import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})

export class NewsAndAlertsService extends BaseService {
  private readonly GET_NEWS_AND_ALERTS_URL = this.generateAPIUrl('NewsAndAlerts/GetNewsAndAlerts');
  private readonly GET_NEWS_AND_ALERTS_SLIDER_URL = this.generateAPIUrl('NewsAndAlerts/GetNewsAndAlertsSlider');
  private readonly GET_NEWS_AND_ALERTSs_ATTACHMENT_LIST_URL = this.generateAPIUrl('NewsAndAlerts/GetNewsAndAlertsAttachmentList');
  private readonly GET_NEWS_AND_ALERTSs_BY_DATE_URL = this.generateAPIUrl('NewsAndAlerts/GetNewsAndAlertsByDate');

  constructor(injector: Injector) {
    super(injector);
  }

  public GetNewsAndAlerts(): Observable<any> {
    return this.http.post<any>(this.GET_NEWS_AND_ALERTS_URL, null);
  }

  public GetNewsAndAlertsSlider(): Observable<any> {
    return this.http.post<any>(this.GET_NEWS_AND_ALERTS_SLIDER_URL, null);
  }

  public GetNewsAndAlertsAttachmentList(payload: any): Observable<any> {
    return this.http.post<any>(this.GET_NEWS_AND_ALERTSs_ATTACHMENT_LIST_URL, payload);
  }

  public GetNewsAndAlertsByDate(payload: any): Observable<any> {
    return this.http.post<any>(this.GET_NEWS_AND_ALERTSs_BY_DATE_URL, payload);
  }

}