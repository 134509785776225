
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
  selector: 'app-oss-copyright',
  templateUrl: './oss-copyright.component.html',
  styleUrls: ['./oss-copyright.component.scss']
})
export class OssCopyrightComponent implements OnInit, OnDestroy {

  public isVisible = true;
  popTitle = "OSS Copyright";
  @Output() close = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void { }

  public closePopUp() {
    this.isVisible = false;
    this.close.emit(true);
  }

  ngOnDestroy() { }

}
