import { Component } from '@angular/core';

@Component({
  selector: 'app-inner-section-layout',
  templateUrl: './inner-section-layout.component.html',
  styleUrls: ['./inner-section-layout.component.scss']
})
export class InnerSectionLayoutComponent {

}
