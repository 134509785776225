import { Component, ViewChild } from '@angular/core';
import { distinct } from '@progress/kendo-data-query';
import { DETAILS_GRID_COLUMN, INVALID_USER_ACCESS_PAGE, DOC_HEADER_NEWS_ALERT } from 'src/app/core/model/constants';
import { TileCode } from 'src/app/core/model/dashboard.model';
import { ExpandColumnDescriptor, GIRGridCellEvent, GirdColumn } from 'src/app/core/model/data-grid.model';
import { KeyValueSelection } from 'src/app/core/model/key-value-pair.model';
import { NewsAlert } from 'src/app/core/model/news-alert.model';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NewsAndAlertsService } from 'src/app/core/services/news-alert.service';
import { DataGridComponent } from 'src/app/shared/common/data-grid/data-grid.component';


@Component({
  selector: 'app-news-alert-component',
  templateUrl: './news-alert-component.html',
  styleUrls: ['./news-alert-component.scss']
})
export class NewsAlertComponent {
  title: string = 'News and Alerts'
  showLastupdated: boolean = false;
  faicon: string = 'fal fa-rss-square';
  public newsAlerts: NewsAlert[] = [];
  public elements: Object[] = []
  public elementsMeta: Object[] = []
  public fromDate: string | undefined
  public toDate: string | undefined
  public columnName: GirdColumn[] = []
  public displayStyle: boolean = false
  public popedUpNewsAlert: NewsAlert[] = []
  public newsAndAlertAttachmentList: KeyValueSelection[] = []
  public noDocumentMessage: string | undefined
  public popupSpecificTitle: string | undefined
  public cellDescription: string | undefined
  public displayAttachments: boolean = false
  public get TileCode(): typeof TileCode { return TileCode; }
  public columnDescriptor : ExpandColumnDescriptor = { isExpanded : true , columnName : 'Title' , columnSize : 500 , otherColumnSize : 100}
  public docTitle : string = DOC_HEADER_NEWS_ALERT.replace('{PrintDate}',(new Date()).toDateString());     
  public distinctData: NewsAlert[] = distinct(this.elements, "Id").map(
    (item) => (item as NewsAlert)
  );
  @ViewChild('newsAlertGrid') newsAlertGrid: DataGridComponent;

  constructor(private newsAndAlertsService: NewsAndAlertsService,
    private navigationService: NavigationService,
    private commonDataService: CommonDataService) { }

  ngOnInit() {
    this.commonDataService.clearAllFilter$.subscribe(x => {
      this.fromDate = null;
      this.toDate = null;
      this.getNewsAndAlertDetail();
    })

    this.getNewsAndAlertDetail();
  }

  private getNewsAndAlertDetail(): void {
    this.newsAndAlertsService.GetNewsAndAlerts().subscribe(resp => {
      this.columnName = []
      resp.ColumnList.forEach(u => {
        this.columnName.push({
          id: u.id, columnTitle: u.columnName, isVisible: u.isVisible
          , isFilterable: u.isFilterable, filterCode: u.filterCode
          , columnField: u.columnDBName
        })
      })
      this.columnName.push(DETAILS_GRID_COLUMN)
      this.elementsMeta = this.columnName.filter(x => x.isVisible == true);
      this.elements = resp.Datatable;
    },
      (error) => {
        if (error.status === 422) {
          this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
        }
      });
  }

  public getNewsAndAlertDetailByDate(): void {
    this.newsAndAlertsService.GetNewsAndAlertsByDate({ fromDate: this.fromDate, toDate: this.toDate })
      .subscribe(resp => {
        this.columnName = []
        resp.ColumnList.forEach(u => {
          this.columnName.push({
            id: u.id, columnTitle: u.columnName, isVisible: u.isVisible
            , isFilterable: u.isFilterable, filterCode: u.filterCode
            , columnField: u.columnDBName
          })
        })
        this.columnName.push(DETAILS_GRID_COLUMN)
        this.elementsMeta = this.columnName.filter(x => x.isVisible == true);
        this.elements = resp.Datatable;
      },
        (error) => {
          if (error.status === 422) {
            this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
          }
        });
  }

  public clearAllFilter(val: any) {
    this.fromDate = null;
    this.toDate = null;
    this.getNewsAndAlertDetail();
  }

  public handleCellClick(item: GIRGridCellEvent) {
    this.popupSpecificTitle = item.columnName + '  for ' + item.jurisdictionNameNews;
    this.cellDescription = item.cellDescription;
    let type = item.newsType;
    let newsId = item.newsId;
    if (item.columnName == 'Details (SOPs)' || item.columnName == 'Details') {
      let payload = { newsType: type, newsId: newsId }
      this.newsAndAlertsService.GetNewsAndAlertsAttachmentList(payload).subscribe(x => {
        this.newsAndAlertAttachmentList = x
      })
      this.displayAttachments = true;
    }
    this.displayStyle = true
  }

  public closePopup(val: any) {
    this.displayStyle = false
    this.noDocumentMessage = undefined;
    this.displayAttachments = false;
    this.popupSpecificTitle = undefined
    this.cellDescription = undefined
  }

  public excelEvent() {
    this.newsAlertGrid.exportToExcel();
  }

  public pdfEvent() {
    this.newsAlertGrid.exportToPdf();
  }

  public printEvent() {
    this.newsAlertGrid.printGrid();
  }
}
