import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, distinct, filterBy } from '@progress/kendo-data-query';
import { CommonDataService } from 'src/app/core/services/common-data.service';

@Component({
  selector: 'multicheck-filter',
  templateUrl: './multi-check-filter-component.html',
  styleUrls: ['./multi-check-filter-component.scss']
})
export class MultiCheckFilterComponent {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: unknown[];
  public showFilter = true;
  private value: unknown[] = [];

  public textAccessor = (dataItem: unknown): string => this.isPrimitive ? dataItem : dataItem[this.textField];
  public valueAccessor = (dataItem: unknown): unknown => this.isPrimitive ? dataItem : dataItem[this.valueField];

  constructor( ) {  }
  ngOnInit(){}

  public ngAfterViewInit(): void {
      this.currentData = this.data;
      this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);

      this.showFilter = typeof this.textAccessor(this.currentData[0]) === 'string';
  }

  public isItemSelected(item: unknown): boolean {
          return this.value.some(x => x === this.valueAccessor(item));
  }

  public onSelectionChange(item: unknown, li: HTMLLIElement): void {
      if (this.value.some(x => x === item)) {
          this.value = this.value.filter(x => x !== item);
      } else {
          this.value.push(item);
      }

      this.filterService.filter({
          filters: this.value.map(value => ({
              field: this.field,
              operator: 'eq',
              value
          })),
          logic: 'or'
      });

      this.onFocus(li);
  }

  public onInput(e: Event): void {
      this.currentData = distinct([
          ...this.currentData.filter(dataItem => this.value.some(val => val === this.valueAccessor(dataItem))),
          ...filterBy(this.data, {
              operator: 'contains',
              field: this.textField,
              value: (e.target as HTMLInputElement).value
          })],
          this.textField
      );
  }

  public onFocus(li: HTMLLIElement): void {
      const ul = li.parentNode as HTMLUListElement;
      const below = ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
      const above = li.offsetTop < ul.scrollTop;

      // Scroll to focused checkbox
      if (above) {
          ul.scrollTop = li.offsetTop;
      }

      if (below) {
          ul.scrollTop += li.offsetHeight;
      }
  }
}
