<ngx-loading-bar #progressBar [color]="'#43B02A'" [includeSpinner]="false"></ngx-loading-bar>
<app-header></app-header>
<app-user-idle-monitoring></app-user-idle-monitoring>
<div class="app-content">
  <div class="header"></div>
   <div class="content">
  <router-outlet></router-outlet>
  </div>
  <div class="footer"></div>
</div>
<app-footer></app-footer>