import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/core/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  constructor(private authService: AuthService
              ,private router : Router ) {  }

  ngOnInit() { }

  login(): void { 
      if(this.authService.isFormLoginEnabled(window.location.href))
          this.router.navigateByUrl('/login');
      else
        this.authService.redirectToAzureAD();
}
  ngOnDestroy(): void { }

}
