import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ReportingDashboardService extends BaseService {
  private readonly GetReportData = this.generateAPIUrl('ReportingDashboard/GetReportingDashboard');
  private readonly GetReportAttachmentFilesData = this.generateAPIUrl('ReportingDashboard/GetReportingDashboardAttachmentList');

  constructor(private injector: Injector) {
    super(injector)
  }

  public getReportData(): Observable<any> {
    return this.http.post<any>(this.GetReportData, null);
  } 
  public getReportAttachmentFileData(payload): Observable<any> {
    return this.http.post<any>(this.GetReportAttachmentFilesData,payload);
  }
}
