<app-inner-section-layout>
  <app-title-bar [title]="title" [showLastupdated]="showLastupdated" [showLastupdated]="true"></app-title-bar>

  <div class="p-4 form-group d-flex form-line">
    <label class="message-heading me-2">Select Jurisdiction</label>
    <div>
      <kendo-dropdownlist [data]="jurisdictions" textField="jurisdictionName" [defaultItem]="defaultJurisdiction"
        valueField="jurisdictionName" [(ngModel)]="selectedJurisdiction"
        (valueChange)="handleJurisdictionchange($event)"></kendo-dropdownlist>
    </div>
  </div>

  <app-datagrid-buttons [Excel]="true" [Pdf]="true"  [ClearFiler]="true" (excelEvent)="excelEvent()"
    (pdfEvent)="pdfEvent()" (printEvent)="printEvent()">
  </app-datagrid-buttons>
  <app-data-grid #infoExchangeGrid [elements]="elements" [elementsMeta]="elementsMeta"  takeMaximumPageSize="true" [heightOffset]="290"
    (handleCellClickEvent)="handleCellClickEvent($event)" gridExportFileName="Information Exchange"
    [pdfTitle]="docTitle" [isPdfEnabled]="true" ></app-data-grid>

  <app-pop-up [dispPopUp]="displayPopup" [displayHeaderSection]='false' (closedPopUpEvent)="closePopup($event)">
    <h4 class="mb-4 popupSepcificTitle">{{popupSpecificTitle}}</h4>
    <div class="mb-4" [innerHTML]="cellDescription | toInnerHtmlCompatible"></div>
  </app-pop-up>

</app-inner-section-layout>