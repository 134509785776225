export class User {
    public userId: string | undefined;
    public companyId: number | undefined;
    public emailId: string | undefined;
    public lastName: string | undefined;
    public firstName: string | undefined;
    public isActive: boolean | undefined;
    public lastActivity: string | undefined;
    public role: string | undefined;
    public tokenExpiration: Date;
    public createDate: Date;
    public idToken: string;
    public fullName: string;
}

