
<div class="d-flex flex-row-reverse mb-2">    
    <button *ngIf="Excel" class="btn btn-action-primary text-nowrap me-3" (click)="excelEventMethod()">
        <em class="fas fa-file-excel me-2" aria-hidden="true" ></em>Excel
    </button>
    <button *ngIf="Pdf" class="btn btn-action-primary text-nowrap me-3" (click)="pdfEventMethod()">
        <em class="fas fa-file-pdf me-2" aria-hidden="true"></em>Pdf
    </button>
    <button *ngIf="Print" class="btn btn-action-primary text-nowrap me-3" (click)="printEventMethod()">
        <em class="fas fa-print me-2" aria-hidden="true"></em>Print
    </button>
    <button *ngIf="ClearFiler" class="btn btn-action-primary text-nowrap me-3" (click)="clearAllFilterEventMethod()">
        <em class="fas fa-filter me-2" aria-hidden="true" ></em>Clear All Filter 
    </button>
    <button *ngIf="Topics" class="btn btn-action-primary text-nowrap me-3" (click)="topicClickEventMethod()">
        <em class="fas fa-hurricane me-2" aria-hidden="true"></em>Topics
    </button>
    <button *ngIf="Delete" class="btn btn-action-primary text-nowrap me-3" (click)="deleteEventMethod()">
        <em class="fas fa-trash me-2" aria-hidden="true"></em>Delete
    </button>
</div>