import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import { SELECT_ITEM } from 'src/app/core/model/constants';

@Component({
    selector: 'dropdown-filter',
    templateUrl: './drop-down-list-filter.component.html',
    styleUrls: ['./drop-down-list-filter.component.scss']
})
export class DropDownListFilterComponent extends BaseFilterCellComponent {
    public get selectedValue(): unknown {
        const filter = this.filterByField(this.valueField);
        return filter ? filter.value : null;
    }

    @Input() public filter: CompositeFilterDescriptor;
    @Input() public data: unknown[];
    @Input() public textField: string;
    @Input() public valueField: string;

    public get defaultItem(): {[Key: string]: unknown} {
        return {
            [this.textField]: SELECT_ITEM,
            [this.valueField]: null
        };
    }

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(value: unknown): void {      
        this.applyFilter(
            value === null ? 
                this.removeFilter(this.valueField) : 
                this.updateFilter({ 
                    field: this.valueField,
                    operator: 'eq',
                    value: value
                })
        ); 
    }
}
