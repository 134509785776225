
import { Pipe, PipeTransform } from '@angular/core';
import { Answer } from 'src/app/core/model/forum.models';

@Pipe({
  name: 'getAnswerOfQue',
  pure: true
})
export class GetAnswerPipe implements PipeTransform {
  transform(question: any, answers: Answer[]): Answer {
    let c = answers.find(x => x.questionId == question.questionId)
    return c ? c : null;
  }
}
