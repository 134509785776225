<div class="right-section">
    <div class="card" >
        <div class="headTitle text-center">
            <em class="fa fa-calendar fa-2x" aria-hidden="true"></em>
        </div>
        <div class="sectionTitle text-center">
            <button class="btn btn-primay floatleft" (click)="deadLineSequanceReverse(true)">
                <em class="fa fa-chevron-left fa-2x  me-1 iconColor" aria-hidden="true"></em>
            </button>
            <label for="" class="heading" >Upcoming Deadlines</label>
            <button class="btn btn-primay floatright" (click)="deadLineSequance(true)">
                <em class="fa fa-chevron-right fa-2x  ms-1 iconColor"   aria-hidden="true"></em>
            </button>
        </div>
        <div class="item-container-deadline" (mouseenter)="setFocusOnDeadlines(true)" (mouseleave)="setFocusOnDeadlines(false)">
            <kendo-listview [data]="deadlinesOnBanner" (scrollBottom)="loadMoreDeadline()">
            <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isLast="isLast" >
                <div class="content text-left ps-1 m-1">
                    <span data-toggle="tooltip" data-placement="left" [title]="dataItem?.deadlineText">
                    <label class="newsDate" for="">{{dataItem.deadlineDate| date: 'MMM dd yyyy'}} :</label>
                    <br>
                    <span class="news"> {{dataItem.deadlineText }}</span>
                    </span>
                </div>
            </ng-template>
        </kendo-listview>
        </div>
    </div>
    <div class="card"  >
        
        <div class="headTitle text-center">
            <em class="fad fa-megaphone fa-2x"></em>
        </div>
        <div class="sectionTitle text-center">
         <button class="btn btn-primay floatleft" (click)="newsAlertSequanceReverse(true)">
                <em class="fa fa-chevron-left fa-2x  me-1" 
                    [ngClass]=" (!isNewsFocused && !isNewsScrolled) ? 'iconColor' : '' " aria-hidden="true"></em>
            </button>
            <label for="" (click)="navigateToNewsAndAlert()" class="heading">News & Alerts</label>
            <button class="btn btn-primay floatright" (click)="newsAlertSequance(true)">
                <em class="fa fa-chevron-right fa-2x  ms-1 "
                [ngClass]=" (!isNewsFocused && !isNewsScrolled) ? 'iconColor' : '' "   aria-hidden="true"></em>
            </button>
        </div>
        <div class="item-container-news" (mouseenter)="setFocusOnNews(true)" (mouseleave)="setFocusOnNews(false)">
         <kendo-listview [data]="newsAlertsOnBanner" (scrollBottom)="loadMoreNews()" >
            <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isLast="isLast" >
                <button [disabled]="dataItem?.newsType === NewsType.Unknown? true : false"
                (click)="openNewsPopup(dataItem)" class="content news-and-alert"
                data-toggle="tooltip" data-placement="left" [title]="dataItem?.newsText">
                <label class="newsDate" for="">{{dataItem?.newsDate| date: 'MMM dd yyyy'}} :</label>
                <br>
                <span class="news"> {{dataItem?.newsText }}</span>
            </button>
            </ng-template>
        </kendo-listview>
      </div>
    </div>
    <div class="card">
        <div class="headTitle text-center">
            <em class="fa fa-envelope fa-2x" aria-hidden="true"></em>
        </div>
        <div class="sectionTitle text-center">
            <ng-container *ngIf="contactUs.length > 0">
                <a  href="mailto:{{contactUs}}" target="_top" class="heading">
                Contact</a></ng-container>
        </div>
    </div>
</div>

<app-pop-up [dispPopUp]="displayStyle" [displayHeaderSection]="false" [modelTitle]='popupTitle' (closedPopUpEvent)="closePopup($event)">
    <h4 class="mb-4 popupSepcificTitle">{{popTitle}}</h4>
    <app-file-link-display [docList]="newsAndAlertAttachmentList" [tileType]="TileCode.NewsAndAlerts"></app-file-link-display> 
    </app-pop-up>