<div class="col-xs-12 col-sm-6 example-col">

    <kendo-dropdownlist
      [value]="value"
      [valuePrimitive]="isPrimitive"
      [data]="data"
      [textField]="textField"
      [valueField]="valueField"
      (valueChange)="onValueChange($event)"
    >
    </kendo-dropdownlist>
  </div>