<app-inner-section-layout>
    <app-title-bar [title]="title" [showLastupdated]="showLastupdated" [faicon]="faicon"></app-title-bar>
    <br />
    <app-datagrid-buttons [Excel]="true" [ClearFiler]="true" [Topics]="true"
        (topicClickEvent)="handleTopicClick($event)" (excelEvent)="excelEvent()">
    </app-datagrid-buttons>

<app-data-grid #reportingGrid [elements]="elements" [elementsMeta]="elementsMeta" [heightOffset]="225"
    (handleCellClickEvent)="handleCellClickEvent($event)" gridExportFileName="Reporting Dashboard"
    [pdfTitle]="docTitle"
    ></app-data-grid>
</app-inner-section-layout>


<app-pop-up [dispPopUp]="displayStyle" [modelTitle]='popupTitle' (closedPopUpEvent)="closePopup($event)">
    <div class="container" *ngIf="columnName?.length > 0">
        <div class="my-2">
            <button class="select-all my-1" [ngClass]="showAll ? 'includedColumn' : 'excludedColumn'"
                 (click)="handleSelection(true)"><span class="">Show All</span></button>
            <button class="select-all my-2" [ngClass]="hideAll ? 'includedColumn' : 'excludedColumn'"
                (click)="handleSelection(false)"><span>Hide All</span> </button>
        </div>
        <div>
            <ul class="listNew">
                <li *ngFor="let item of columnName">
                    <span class="listButtons" (click)="handleColumnSelection(item)"
                        [ngClass]="item.isVisible== true ? 'includedColumn' : 'excludedColumn'"> {{item.columnTitle}} </span>
                </li>
            </ul>
        </div>
    </div>
    <p *ngIf="columnName.length==0">
       {{ noItemForSelection}}
    </p>
</app-pop-up>

<app-pop-up [dispPopUp]="displayDoc" [displayHeaderSection]='false' (closedPopUpEvent)="closeDocPopup($event)">
    <h4 class="mb-4 popupSepcificTitle">{{popupSpecificTitle}}</h4>
    <div class="mb-4" [innerHTML]="cellDescription | toInnerHtmlCompatible"></div>
    <ng-container *ngIf="displayAttachments == true">
        <app-file-link-display [docList]="docList" [tileType]="TileCode.ReportingDashboard"></app-file-link-display>   
    </ng-container>
</app-pop-up>
