import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { AppSettings } from '../model/app-setting.model';
import { AppSettingsService } from './app-settings.service';

@Injectable({
    providedIn: 'root'
})

export class BaseService {
    public appSettings: AppSettings;
    public apiBaseUrl: string;
    public appSettingsService: AppSettingsService;
    public http: HttpClient;

    constructor(injector: Injector) {
        this.appSettingsService = injector.get(AppSettingsService);
        this.http = injector.get(HttpClient);
        this.appSettings = this.appSettingsService.getAllSettings();
        this.apiBaseUrl = this.appSettings.apiBaseUrl;
    }

    public generateAPIUrl(path: string): string {
        const prefix = '/API/api/';
        return this.apiBaseUrl.concat(prefix).concat(path);
    }

}