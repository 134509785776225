import { Component, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { ExcelExportEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { COPY_RIGHT, DOC_GENERATE_STARTED, DOWNLOAD_SAVE_TEXT, DOWNLOAD_SUCCESS_TEXT, EXCEL_CELL_HEIGHT, EXCEL_CELL_WIDTH, EXCEL_COLUMN_WIDTH, PDF_EXTENSION } from 'src/app/core/model/constants';
import { ExpandColumnDescriptor, ExportDocumentType } from 'src/app/core/model/data-grid.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { saveAs } from "@progress/kendo-file-saver";
import { State } from '@progress/kendo-data-query';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { AppSettings } from 'src/app/core/model/app-setting.model';
import { KeyValue } from 'src/app/core/model/key-value-pair.model';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
@Component({
  selector: 'app-print-grid',
  templateUrl: './print-grid.component.html',
  styleUrls: ['./print-grid.component.scss']
})
export class PrintGridComponent {
  @Input() elementsMeta: any[] = [];
  public elements: any[] = []
  @Input() dispPopUp: boolean = true
  @Input() heightOffset: number;
  @Input() gridExportFileName: string = "Data Grid";
  @Input() pdfTitle: string = ''
  @Input() columnDescriptor: ExpandColumnDescriptor = { isExpanded: false, columnName: '', columnSize: 0, otherColumnSize: 0 };
  @Input() gridHeight: number = 400

  public excelTitle = this.pdfTitle
  public copyRight: string = COPY_RIGHT;
  public gridData: any[] = [];
  public finalGridData: GridDataResult;
  public exportDocumentType: ExportDocumentType
  public loadPercentage: string = '0'
  @Output() closedPrintGrid = new EventEmitter();
  @ViewChild('KGrid') grid: GridComponent;
  public appSettings: AppSettings
  public replaceKeyValue: KeyValue[] = []
  public pdfExtention: string = PDF_EXTENSION
  public autoPrint: boolean = false;
  public buttonCount: number = 5;
  public initialGridRecordsNumber: number = 100000
  public totalSize: number = 100000;
  public gridState: State = { skip: 0, take: this.initialGridRecordsNumber };

  public progressStatus: LabelSettings = { visible: true, format: "percent", position: "center", };
  public progressStyles: { [key: string]: string } = { color: "black", background: "#86bc25",};

  constructor(private notifyService: NotifyService
    , private appSettingsService: AppSettingsService
    , private ngZone: NgZone) { }

  ngOnInit() {
    this.initialLoad();
    this.appSettings = this.appSettingsService.getAllSettings();
    this.replaceKeyValue = JSON.parse(this.appSettings.EscapeCharsAndValue) as KeyValue[];
  }

  private initialLoad(refresh: boolean = false): void {
    this.notifyService.info(DOC_GENERATE_STARTED);
    this.totalSize = this.elements.length;
    this.finalGridData = {
      data: this.elements.slice(0, 15),
      total: 15,
    };

    let myInt = setInterval(() => {
      let currentSize = this.finalGridData.data.length;
      if (currentSize >= this.totalSize) {
        clearInterval(myInt)
        if (this.exportDocumentType == ExportDocumentType.Excel)
          this.exportToExcel();
        else if (this.exportDocumentType == ExportDocumentType.Pdf)
          this.exportToPdf();
      }
      else
        this.loadMoreData()
    }, 100)
  }

  public loadMoreData() {
    const next = this.finalGridData.data.length;
    this.finalGridData.data = [...this.finalGridData.data, ...this.elements.slice(next, next + 20)];
    this.finalGridData.total = this.finalGridData.data.length;
    this.loadPercentage = Math.round((this.finalGridData.total / this.totalSize) * 100).toFixed(1);
  }

  public exportToExcel() {
    this.excelExportBackgroundFunc();
  }

  public exportToPdf() {
    this.autoPrint = false
    this.printBackgroundFunc();
  }

  public printGrid() {
    this.autoPrint = true;
    this.printBackgroundFunc();
  }

  private printBackgroundFunc() {
    this.notifyService.info(DOWNLOAD_SAVE_TEXT)
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => {
        this.grid.saveAsPDF();
        this.notifyService.success(DOWNLOAD_SUCCESS_TEXT);
        this.closedPrintGrid.emit('true');
        this.closePopUp(false);
      });
    }, 100);
  }

  private excelExportBackgroundFunc() {
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => this.grid.saveAsExcel());
    }, 100);
  }

  public onExcelExport(args: ExcelExportEvent): void {
    this.notifyService.info(DOWNLOAD_SAVE_TEXT)
    let cellHeight = EXCEL_CELL_HEIGHT
    let cellWidth = EXCEL_CELL_WIDTH
    this.excelTitle = this.pdfTitle
    args.preventDefault();
    const workbook = args.workbook;
    let rows = workbook.sheets[0].rows;
    rows.forEach((row) => {
      if (row.type == "data")
        row.cells.forEach((cell) => {
          cell.width = EXCEL_COLUMN_WIDTH;
          cell.wrap = true;
          cell.value = this.replaceValues(cell?.value);
          let value = cell?.value;
          if (value?.length / cellWidth > 0) {
            let newHeight = Math.round((value?.length / cellWidth + 1) * cellHeight);
            row.height = row.height > newHeight ? row.height : newHeight;
          }
        });
    });
    rows.unshift({ cells: [{ value: this.copyRight, colSpan: 4, rowSpan: 0 }] });
    rows.unshift({ cells: [{ value: this.excelTitle, colSpan: 4, rowSpan: 0 }] });
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, this.gridExportFileName + '.xlsx');
      this.notifyService.success(DOWNLOAD_SUCCESS_TEXT)
      this.closedPrintGrid.emit('true');
      this.closePopUp(false);
    });
  }

  public closePopUp(val: any) {
    this.dispPopUp = false;
  }

  public replaceValues(inputValue: string) {
    if (inputValue != undefined || inputValue != null) {
      this.replaceKeyValue
        .forEach(x => inputValue = inputValue.split(x.key).join(x.value.toString()))
    }
    return inputValue;
  }
}
