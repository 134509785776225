import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppSettingsService } from './core/services/app-settings.service';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './shared/layout/layout.module';
import { UserIdleModule } from 'angular-user-idle';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './auth/login/login.component';
import { CommonModule } from '@angular/common';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientInterceptor } from './core/interceptors/client.interceptor';
import { CoreModule } from './core/core.module';
import { AppPreloadingStrategy } from './core/app-preloading-strategy';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const appInitializerFn = (appSetting: AppSettingsService) => {
  return () => {
    return appSetting.loadAppSettings();
  };
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    LayoutModule,
    SharedModule,
    NotificationModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    UserIdleModule.forRoot({idle: 1500, timeout: 120, ping: 90})

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppSettingsService]
    },
    AppPreloadingStrategy
  ],
  
  bootstrap: [AppComponent]
})

export class AppModule { }