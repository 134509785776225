import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-progress-popup',
  templateUrl: './progress-popup.component.html',
  styleUrls: ['./progress-popup.component.scss'],
  animations: [
    trigger('pageTransition', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0 }))
      ])
    ]),
  ]
})

export class ProgressPopupComponent implements OnInit {
    @Input() progressMessage: string | TemplateRef<any>;
    @Input() progressTitle: string | TemplateRef<any>;
    @Input() showProgressPopup: boolean | TemplateRef<any>;

  constructor() {
  }

  ngOnInit(): void {
  }

  public close() {
    this.showProgressPopup = false;
}

}
