<div *ngIf="menuItem !== undefined" class="sidenav-new">
  <div class="d-flex flex-row-reverse end">
    <button *ngIf="sidebarToggleValue " class="btn btn-action-dark text-nowrap me-3 collapseButton"
      (click)="sidebarToggle()">
      <span class="icon"><em class="fad fa-arrow-circle-left me-2" aria-hidden="true"></em>Collapse</span>
    </button>
  </div>
  <div class="d-flex">
    <button *ngIf="!sidebarToggleValue" class="btn btn-action-dark text-nowrap me-3 expandButton"
      (click)="sidebarToggle()">
      <span class="icon"><em class="fad fa-arrow-circle-right me-1" aria-hidden="true"></em></span>
    </button>
  </div>
  <div *ngIf="sidebarToggleValue" class="workspace-nav">
    <ul class="workspace-main-nav" style="display: block;">
      <li class="workspace-menu-item" *ngFor="let item of menuItem">
        <div [ngClass]="(item.id == selectedTracker.id) ? 'sideNavClicked': ''">
          <a class="btn anchorStyle" [routerLink]="[item.routerLink]" (click)="handleTrackerClick(item)">
            <span class="icon"><em class="{{item.imageName}}" aria-hidden="true"></em></span>
            <span class="nav-name" title="{{item.name}}">{{item.name}} </span>
            <span *ngIf="item.isSelected && item.isMultiselect"><em class="fas fa-angle-down float-end"></em></span>
            <span *ngIf="!item.isSelected && item.isMultiselect"><em class="fas fa-angle-right float-end"></em></span>
          </a>
        </div>
        <div *ngIf=" item.children && item.children!.length>0">
          <app-child-menu [toggleListSection]="(item.isSelected==true ? true :  false)" [menuItem]="item"
            [parentId]="selectedTracker.id" (updateSubcategoryDataEvent)="handleUpdates($event)"
            [showSelectedSquare]="showSelectedSquare">
          </app-child-menu>
        </div>
      </li>
    </ul>
  </div>
</div>