import { Observable, of } from 'rxjs';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DETAILS_CELL, NO_ATTACHMENT_AVAILABLE, NO_ENTERPRISE_POLICY } from 'src/app/core/model/constants';
import { TileCode } from 'src/app/core/model/dashboard.model';
import { GIRGridCellEvent, GirdColumn } from 'src/app/core/model/data-grid.model';
import { GIRUserRole } from 'src/app/core/model/enum.model';
import { KeyValueSelection } from 'src/app/core/model/key-value-pair.model';
import { Jurisdiction } from 'src/app/core/model/tracker.models';
import { AuthService } from 'src/app/core/services/auth.service'
import { QiDashboardService } from 'src/app/core/services/qi-dashboard.service';
import { QiQddTrackerService } from 'src/app/core/services/qi-qdd-tracker.service';
import { TileServiceService } from 'src/app/core/services/tile-service.service';
import { DataGridComponent } from '../data-grid/data-grid.component';

@Component({
  selector: 'app-qi-qdd-grid',
  templateUrl: './qi-qdd-grid.component.html',
  styleUrls: ['./qi-qdd-grid.component.scss']
})
export class QiQddGridComponent {

  @Input() elements: Object[] = []
  @Input() elementsMeta: Object[] = []
  @Input() columnName: GirdColumn[] = []
  @Input() tileService: TileServiceService
  @Input() qddTrackerService: QiQddTrackerService
  @Input() qiDashboardService: QiDashboardService
  @Input() heightOffset: number;

  public dataGridCellEvent: GIRGridCellEvent | undefined
  public ViewPopup: boolean = false
  public cellIsOfDetailsType: boolean = false
  public popupSpecificTitle: string | undefined
  public isCountrySpecific: boolean = false
  public cellDescription: string | undefined
  public attachementList: KeyValueSelection[] = []
  public noAttachmentAvailable: string | undefined
  public enterprisePolicy: string | undefined
  public selectedCountry: Jurisdiction | undefined

  public availableCountries: Jurisdiction[] = []
  public get TileCode(): typeof TileCode { return TileCode; }
  public get GIRUserRole(): typeof GIRUserRole { return GIRUserRole; }
  public userRole: number | undefined
  public saveEnterprisePolicy: boolean = false
  public jurisdictionIdObs$: Observable<any>
  public dataElementIdObs$: Observable<any>
  @ViewChild('qiQddGrid') qiQddGrid: DataGridComponent;
  constructor(private authService: AuthService, private elem: ElementRef) { }

  ngOnInit() {
    this.userRole = Number(this.authService.getAuthUser().role)
    this.elem.nativeElement.style.setProperty('--grid-height-offset', (this.heightOffset.toString() + 'px'));
    this.accessCountries();
  }

  public accessCountries() {
    this.qiDashboardService.getCountryData().subscribe(resp => {
      this.availableCountries = resp
      this.availableCountries.forEach(x => { x.isItemSelected = false });
    });
  }

  public handleCellClickEvent(item: GIRGridCellEvent) {
    this.dataGridCellEvent = item;
    this.cellIsOfDetailsType = DETAILS_CELL == item.columnName ? true : false;
    this.popupSpecificTitle = item.columnName + '  for ' + item.dataElementNameQDD;
    this.isCountrySpecific = item.IsCountrySpecific == true
    this.cellDescription = item.cellDescription;
    if (this.cellIsOfDetailsType) {
      if (!this.isCountrySpecific) {
        let payload = { qiDashboardId: item.id }
        this.qddTrackerService.getQIQDDDAttachments(payload)
          .subscribe(resp => {
            this.attachementList = resp;

            if (this.attachementList.length == 0)
              this.noAttachmentAvailable = NO_ATTACHMENT_AVAILABLE;
          })
      }
      let payload = { dataElementId: item.id }
      this.tileService.getEnterprisePolicy(payload)
        .subscribe(resp => {
          this.enterprisePolicy = resp;
          if (this.enterprisePolicy.length == 0)
            this.enterprisePolicy = NO_ENTERPRISE_POLICY;
        })
    }
    this.ViewPopup = true
  }

  public handleCountryChange(val: any) {
    let payload = {
      qiDashboardId: this.dataGridCellEvent.id
      , jurisdictionId: this.selectedCountry?.jurisdictionId
    }
    this.qddTrackerService.getQIQDDDAttachments(payload)
      .subscribe(resp => {
        this.attachementList = resp;

        if (this.attachementList.length == 0)
          this.noAttachmentAvailable = NO_ATTACHMENT_AVAILABLE;
      })
  }

  public closePopup(val: any) {
    this.ViewPopup = false;
    this.dataGridCellEvent = undefined
    this.popupSpecificTitle = undefined
    this.cellDescription = undefined
    this.enterprisePolicy = undefined
    this.noAttachmentAvailable = undefined
    this.attachementList = undefined
  }

  public publishEnterPrisePolicy() {
    this.saveEnterprisePolicy = true;
    this.jurisdictionIdObs$ = of(this.selectedCountry?.jurisdictionId ?? 0)
    this.dataElementIdObs$ = of(this.dataGridCellEvent?.id ?? 0)
  }

  public savePopup(val: any) {
    this.saveEnterprisePolicy = false;
  }

  public excelEvent() {
    this.qiQddGrid.exportToExcel();
  }

}
