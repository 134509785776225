import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from 'src/app/core/services/layout-service';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss'],
})

export class LayoutContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isShowFooter = true;
  public isSidebarCollapsed$: Observable<boolean>;
  @ViewChild('leftSidebar') leftSidebar;

  constructor(private layoutService: LayoutService) {
    this.isSidebarCollapsed$ = this.layoutService.sidebarChange;
  }

  ngOnInit(): void { }

  get hasSidebar(): boolean {
    return this.leftSidebar.nativeElement && this.leftSidebar.nativeElement.children.length > 0;
  }

  ngAfterViewInit(): void {
    if (this.hasSidebar) {
      this.layoutService.hasSidebar$.next(true);
    }
  }

  ngOnDestroy(): void {
    this.layoutService.hasSidebar$.next(false);
  }

}
