<kendo-dialog class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': dispPopUp ? 'block' :'none'}">
  <div class="pop-up-background">
    <div class="card cardwidth">
      <div *ngIf="displayHeaderSection" class="modal-header ">
        <h4 class="header">{{modelTitle}}</h4>
      </div>
      <div class="card-body">
        <ng-content></ng-content>
      </div>
    <ng-container *ngIf="showfooter==true">
      <div class="modal-footer">
        <button type="button" class="btn btn-action-primary text-nowrap" (click)="closePopup()">
          Close
        </button>
      </div>
    </ng-container>
    </div>
  </div>
</kendo-dialog>