import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-privacy',
  templateUrl: './privacy.component.html'
})

export class PrivacyComponent implements OnInit {


  constructor() {
  }

  ngOnInit() { }
}