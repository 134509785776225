<footer class="footer">
  <div *ngIf="copyright.length > 0" class="copyright-text">
    <div class="mb-0" [innerHTML]="copyright"></div>
  </div>
  <ul class="footer-links">
    <li *ngIf="privacy != null"><a href={{privacy}} target="_new" referrerpolicy="no-referrer">Privacy</a></li>
    <li><a class="optanon-toggle-display" referrerpolicy="no-referrer">Cookie Settings</a></li>
    <li><a target="_new" (click)="ossCopyrightClick()" referrerpolicy="no-referrer">OSS Copyright</a></li>
    <li *ngIf="ToU != null"><a href={{ToU}} target="_new" referrerpolicy="no-referrer">Terms of Use</a></li>
    <li *ngIf="cookies != null"><a href={{cookies}} target="_new" referrerpolicy="no-referrer">Cookies</a></li>
    <li *ngIf="contactUs.length > 0"><a href="mailto:{{contactUs}}" target="_top">Contact Us</a></li>
  </ul>
</footer>

<app-oss-copyright *ngIf="isShowCopyrightPopup" (close)="isShowCopyrightPopup = false"></app-oss-copyright>