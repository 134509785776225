<ng-container *ngIf="isShow$ | async">
  <kendo-dialog *ngIf="isShow$ | async" (close)="close()" class="pop-up">
    <span class="header row message-heading" [innerHTML]="confirmationTitle | safeHtml"></span>
    <br />
    <br />
    <div class="d-flex flex-column timeout">
      <span class="election-message" [innerHTML]="confirmationMessage | safeHtml"></span>
      <br />
      <div class="icon">
        <em class="fad fa-alarm-exclamation icon" aria-hidden="true"></em>
      </div>
      <br />
      <h6>You will be logged out in {{timer$ | async}} seconds!</h6>
      <br />
      <div class="d-flex flex-row action">
        <button (click)="logout()" class="btn btn-action-primary text-nowrap ms-3 me-3">Logout
        </button>
        <button (click)="continue()" class="btn btn-action-light text-nowrap ms-3 me-3">Continue
        </button>
      </div>
    </div>
  </kendo-dialog>
</ng-container>