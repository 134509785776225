<ul>
  <p class="list-title">Attachment(s) and Link(s):</p>
  <li *ngFor="let item of docList" class="newsList">
    <span *ngIf="(item?.name| displayPdfIcon) == false">
      <em class="fas fa-link mx-2"></em><a [href]="item.name" target="_blank">{{item.name}}</a>
    </span>
    <br>
    <span *ngIf="(item?.name| displayPdfIcon)">
      <button class="downloadButton" (click)="downloadReportingDashboard(item)"><em
          class="far fa-file-pdf fa-1x mx-2"></em><span> {{item.name}}</span></button>
    </span>
  </li>
  <p *ngIf="docList?.length==0">
    <span class=" mt-5 pt-5">
      <p>{{noDocumentMessage}}</p>
    </span>
  </p>
</ul>
<ng-template #downloadFile></ng-template>
