import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CONTACT_US, COOKIE, COPYRIGHT, PRIVACY, TOU } from 'src/app/core/model/constants';

const PRIVACY_LINK = 'privacyLink';
const CONTACT_LINK = 'contactLink';
const COPYRIGHT_LINK = 'copyrightLink';
const TERMS_OF_USE_LINK = 'termsOfUseLink';
const COOKIES_LINK = 'cookiesLink';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  public showConfirmationPopup: boolean = false;
  public defaultVisible: boolean;
  public contactUs: string = "";
  public privacy: string = "";
  public cookies: string = "";
  public ToU: string = "";
  public copyright: string = "";
  public isShowCopyrightPopup = false;
  showScrollbarModes: any[];
  scrollbarMode: string;

  constructor() {
    this.defaultVisible = false;

    this.showScrollbarModes = [{
      text: "On Scroll",
      value: "onScroll"
    }, {
      text: "On Hover",
      value: "onHover"
    }, {
      text: "Always",
      value: "always"
    }, {
      text: "Never",
      value: "never"
    }];

    this.scrollbarMode = this.showScrollbarModes[0].value;
  }

  ngOnInit() { 
    this.showPrivacy();
    this.showToU();
    this.showContactUs();
    this.showCopyright();
    this.showCookies();
  }
  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }

  ossCopyrightClick() {
    this.isShowCopyrightPopup = true;
  }

  public showPrivacy() {
    this.privacy = PRIVACY;
  }

  public showToU() {
    this.ToU = TOU;
  }

  public showCookies() {
    this.cookies = COOKIE;
  }

  public showContactUs() {
    this.contactUs = CONTACT_US;
  }

  public showCopyright() {
    this.copyright = COPYRIGHT;
  }

}
