import { ChangeDetectionStrategy, Component, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, interval, Observable, of, Subject, } from 'rxjs';
import { map, mergeMap, startWith, take, takeUntil } from 'rxjs/operators';
import { UserIdleService } from 'angular-user-idle';
import { BaseService } from 'src/app/core/services/base.service';
import { SESSION_TIMEOUT_MESSAGE, SESSION_TIMEOUT_TITLE } from 'src/app/core/model/constants';
import { AuthService } from 'src/app/core/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-user-idle-monitoring',
  templateUrl: './user-idle-monitoring.component.html',
  styleUrls: ['./user-idle-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserIdleMonitoringComponent  extends BaseService implements OnInit, OnDestroy {
  public isShow$: Subject<boolean> = new Subject();
  public loggoutInSeconds = this.appSettings.session.timeout;
  public timer$: Observable<number>;
  public stopCountdown$: Subject<boolean> = new Subject();
  public initialized = false;
  public confirmationMessage: string;
  public confirmationTitle: string;

  constructor(injector: Injector, private zone: NgZone, private authService: AuthService, private userIdle: UserIdleService) {
    super(injector);
    this.confirmationMessage = SESSION_TIMEOUT_MESSAGE;
    this.confirmationTitle = SESSION_TIMEOUT_TITLE;
  }

  ngOnInit(): void {
    this.userIdle.stopWatching();
    this.userIdle.setConfigValues({
      idle: this.appSettings.session.idleTime,
      timeout: this.appSettings.session.timeout,
      ping: this.appSettings.session.ping
    });

    this.authService.authUser$.subscribe(user => {
      if (user && !this.initialized) {
        this.userIdle.startWatching();
        this.initialized = true;
        this.userIdle.ping$.subscribe(() =>  this.authService.UpdateTokenExpiration());
      }
    });
 
    this.userIdle.onTimerStart().subscribe(count => 
      this.startCountdown(count)
    );
 
    this.userIdle.onTimeout().subscribe(() => 
      this.logout()
    );
  }

  
  private startCountdown(count: number): Observable<any> {
    if(count > 0)
    {
      this.isShow$.next(true);
      this.timer$ = interval(1000).pipe(
        take(this.loggoutInSeconds),
        takeUntil(this.stopCountdown$),
        untilDestroyed(this),
        startWith(-1),
        map(i => this.loggoutInSeconds - count),
      );
    }
    else
    {
      this.isShow$.next(false);
    }
    return this.timer$;
  }

  public continue() {
    this.stopCountdown$.next(true);
    this.isShow$.next(false);
    this.userIdle.stopTimer();
    this.authService.renewAccessToken();
  }

  public logout() {
    this.stopCountdown$.next(true);
    this.isShow$.next(false);
    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.authService.logout();
  }

  ngOnDestroy(): void {
  }

}
