<div class="mt-2 data">
    <app-data-grid #crsFatcaGrid [elements]="elements" [elementsMeta]="elementsMeta" 
        (handleCellClickEvent)="handleCellClickEvent($event)" [heightOffset]="heightOffset" gridExportFileName="CRS FATCA Tracker">
    </app-data-grid>
</div>

<app-pop-up [dispPopUp]="viewPopup" [displayHeaderSection]=false (closedPopUpEvent)="closePopUp($event)">
    <h4 class="mb-4 popupSepcificTitle">{{popupSpecificTitle}}</h4>
    <div class="mb-4" [innerHTML]="cellDescription | toInnerHtmlCompatible"></div>
    <ng-container *ngIf="! cellIsNonJurisdictionType">
        <app-file-link-display [docList]="attachementList" [tileType]="TileCode.CRSFatca"></app-file-link-display> 
        <div class="mb-4 ">
            <p class="list-title">Enterpise Policy</p>
            <P> {{enterprisePolicy}} </P>
        </div>
        <ng-container *ngIf="(userRole == GIRUserRole.CTM) ? true : false">
        <button class="btn btn-action-primary text-nowrap mb-4" (click)="publishEnterPrisePolicy()">
            Publish Enterprise Policy</button>
       </ng-container>
        <br>
        <ng-container *ngIf="enableViewHistory">
        <button class="btn btn-action-primary text-nowrap" (click)="showHistoryEventM()">
             History Of Changes</button>
        </ng-container>
    </ng-container>
</app-pop-up>

<app-pop-up  [dispPopUp]=" historyPopup" [displayHeaderSection] = false  (closedPopUpEvent)="closeHistroyPopup($event)">                
    <h4 class="mb-4 popupSepcificTitle">{{popupSpecificTitle}}</h4>
    <app-data-grid [elements]="historyElements" 
                   [elementsMeta]="historyElementsMeta"
                   [heightOffset]="300"
                   class="appDataGrid">
   </app-data-grid>
</app-pop-up>

<app-pop-up [dispPopUp]=" saveEnterprisePolicy" [displayHeaderSection]=false (closedPopUpEvent)="closePopup($event)">
    <app-enterprise-policy-save 
        [jurisdictionId]="jurisdictionIdObs$ | async"
        [dataElementId]="dataElementIdObs$ | async"
        [tileService]="tileService!">
    </app-enterprise-policy-save>
</app-pop-up>
