
import { Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { CURRENT_TILE } from 'src/app/core/model/constants';
import { KeyValuePair } from 'src/app/core/model/key-value-pair.model';
import { MenuItem, SubCategory, TrackerCode, TrackerSections } from 'src/app/core/model/tracker.models';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { SavedViewsService } from 'src/app/core/services/saved-views.service';
import { TileServiceService } from 'src/app/core/services/tile-service.service';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss']
})

export class LeftmenuComponent {
  public toggleStatus: boolean = false;
  public menuItem: TrackerSections[] | undefined
  public trackerViewData$: Observable<SubCategory[]>
  public subCategoryList: number[] = []
  public trackerData$: Observable<TrackerSections[]> | undefined
  public savedViewTracker$: Observable<TrackerSections[]> | undefined
  public categories: MenuItem[] = []
  public currentTile: KeyValuePair
  public selectedTracker: TrackerSections
  public previousTracker: TrackerSections
  public additionalLeftMenu: TrackerSections[] = []
  public leftMenu: TrackerSections[] = []
  public sidebarToggleValue: boolean | undefined;
  public toggleSubscription: Subscription;
  public showSelectedSquare: boolean = true;

  @ViewChildren(RouterLink) accessFirstLink: QueryList<RouterLink>;
  @Output() subCategoryListEvent = new EventEmitter<number[]>()

  constructor(private tileService: TileServiceService
    , private commonDataService: CommonDataService
    , private dashBoardService: DashboardService
    , private savedViewsService: SavedViewsService) {
  }

  ngOnInit() {
    this.toggleSubscription = this.commonDataService.sideBarToggle$.subscribe(x => {
      this.sidebarToggleValue = x;
    })

    this.commonDataService
      .selectedTile$
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        switchMap(x => {
          if (x.id == 0) {
            const previousTile = sessionStorage.getItem(CURRENT_TILE);
            if (previousTile != null && previousTile != undefined)
              x = JSON.parse(atob(previousTile));
          }
          sessionStorage.setItem(CURRENT_TILE, btoa(JSON.stringify(x)));
          return of(x)
        })
      )
      .pipe(
        tap(x => {
          this.currentTile = x;
          this.trackerData$ = this.tileService.getTrackerByTileId({ tileId: this.currentTile.id });
          this.savedViewTracker$ = this.savedViewsService.getSavedViewTrackerList({ tileId: this.currentTile.id });
        }),
        switchMap(x => this.dashBoardService.getLeftSideMenuDetail({ tileId: x.id }))
      ).subscribe(resp => {
        this.leftMenu = [];
        resp.forEach(x =>
          this.leftMenu.push({
            menuId: x.id, id: x.trackerId, name: x.menuName, templateId: x.templateId,
            routerLink: x.routerLink, children: [], isSelected: false, trackerType: 1,
            menuCode: x.menuCode, isMultiselect: x.isMultiselect, imageName: x.imageName
          }))
        this.leftMenu[0].isSelected = true;
        this.selectedTracker = this.leftMenu[0]
        this.fetchTrackerTreeData();
      })
  }

  public sidebarToggle() {
    this.commonDataService.sideBarToggle$.next(!this.sidebarToggleValue)
  }

  public handleTrackerClick(tracker: TrackerSections) {
    this.toggleStatus = true;
    if (this.selectedTracker.id != tracker.id) {
      this.selectedTracker.isSelected = false;
      this.selectedTracker = tracker
      tracker.isSelected = true
    }
    else {
      this.toggleStatus = false
      this.selectedTracker.isSelected = !this.selectedTracker.isSelected
    }

    this.showSelectedSquare = true
    if (this.selectedTracker.menuCode == TrackerCode.SAVED_VIEW)
      this.showSelectedSquare = false

    if (this.toggleStatus) {
      if (this.selectedTracker.menuCode == TrackerCode.SAVED_VIEW) {
        this.fetchSavedViewTrackerData()
      }
      else {
        this.commonDataService.selectedTracker$.next(tracker);
        this.fetchTrackerTreeData();
      }
    }
  }

  public fetchSavedViewTrackerData() {
    this.savedViewsService.getSavedViewTrackerList({ tileId: this.currentTile.id }).subscribe((resp) => {
      let dataArray = []
      resp.forEach(x => dataArray.push({ id: x.id, name: x.name, isSelected: false, expanded: false, children: [] }))
      this.leftMenu.filter(t => t.id === this.selectedTracker.id)
        .forEach(x => { x.children = dataArray; x.isSelected = true })
      this.menuItem = this.leftMenu
    })
  }

  public fetchTrackerTreeData() {
    this.trackerViewData$ = this.tileService.getTrackerViewByTrackerId({ tileId: this.currentTile.id, trackerId: this.selectedTracker.id });
    this.processViewData();
  }

  public processViewData() {
    this.categories = []
    this.trackerViewData$.subscribe(data => {
      let dataArray: SubCategory[] = data
      dataArray.forEach(x => {
        let childernArray: MenuItem[] = []
        dataArray.filter(t => t.categoryId == x.categoryId)
          .forEach(item => childernArray
            .push({ id: item.subCategoryId, name: item.subCategoryName, isSelected: false, expanded: false, children: [] }))

        let item: MenuItem = { id: x.categoryId, name: x.categoryName, isSelected: false, expanded: false, children: childernArray }
        const existingItem = this.categories.filter(u => u.id === item.id)
        if (existingItem.length == 0)
          this.categories.push(item)
      })
    });
    this.trackerData$.subscribe(data => {
      if (this.leftMenu?.length! > 0) {
        let tc = this.leftMenu.find(t => t.id === this.selectedTracker.id)
        if (tc != undefined && tc != null) {
          tc.children = this.categories;
          tc.isSelected = true
        }
        this.menuItem = this.leftMenu
      }
    }
    )
  }

  public handleUpdates(val: any) {
    this.subCategoryList = []
    var category = this.menuItem.filter(x => x.id === this.selectedTracker.id);
    category?.forEach(x => { x.children?.forEach(u => this.selectSubcategoryId(u)) })
    this.subCategoryListEvent.emit(this.subCategoryList);
    this.commonDataService.newSubcategoryList$.next(this.subCategoryList);
  }

  public selectSubcategoryId(catOrSubcat: MenuItem) {
    if (catOrSubcat.children && catOrSubcat.children.length > 0)
      catOrSubcat.children!.forEach(x => this.selectSubcategoryId(x))
    else {
      if (catOrSubcat.isSelected) {
        if (this.subCategoryList.indexOf(catOrSubcat.id) == -1)
          this.subCategoryList.push(catOrSubcat.id);
      }
    }
  }

  ngAfterViewInit(): void {
    this.accessFirstLink.changes.pipe(take(1))
      .subscribe(items => {
        let trackerIndex = items._results.indexOf(items._results.find(x => x.href.includes('template')))
        trackerIndex = trackerIndex > 0 ? trackerIndex : 0
        if (items._results[trackerIndex])
          items._results[trackerIndex].el.nativeElement.click();
        else
          items.first.el.nativeElement.click();
      });
  }

  ngOnDistroy() {
    this.toggleSubscription.unsubscribe();
  }

}



