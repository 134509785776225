import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from '../services/auth.service';
import { NotifyService } from '../services/notify.service';
import { HTTP_403_ERROR_MESSAGE, HTTP_422_ERROR_MESSAGE, HTTP_500_ERROR_MESSAGE } from '../model/constants';

const UserContext = {
    UserGuid: '',
    CompanyId: 0,
    RequestId: '86b53c81-616f-4ad7-86b2-bcf9d2d60a44'
};

@Injectable()
export class ClientInterceptor implements HttpInterceptor {
    private navigationService: NavigationService;
    private notifyService: NotifyService;
    private authService: AuthService;

    constructor(private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('appSettings.json')) {
            return next.handle(request);
        }
        this.notifyService = this.injector.get(NotifyService);
        this.authService = this.injector.get(AuthService);

        UserContext.CompanyId = this.authService.authUser ? this.authService.authUser.companyId: 0;
        UserContext.UserGuid = (this.authService.authUser && this.authService.authUser.userId) ? this.authService.authUser.userId : '00000000-0000-0000-0000-000000000000';
        
        //UserContext.ApplicationId= 0
        //UserContext.UserGuid='66D0D640-09D1-444C-A41D-E6B7B14B0801'

        request = request.clone({
            setHeaders: {
                UserContext: JSON.stringify(UserContext),
                authorization: `Bearer ${this.authService.getToken()}`,
            }
        });

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status >= 500) {
                        this.handle500ServerError(error);
                    } else if (error.status === 401) {
                        this.handle401UnAuthenticated(error);
                    } else if (error.status === 403) {
                        this.handle403UnAuthorized(error);
                    } else if (error.status === 422) {
                        this.handle422ServerError(error);
                    } else if (error.status === 404) {
                        this.handle404ServerError(error);
                    } else if (error.status === 475) {
                        this.handle475ServerError(error);
                    } else {
                        console.error(error);
                        return throwError(error);
                    }
                }
                return throwError(error);
            })
        );
    }

    private handle401UnAuthenticated(error: HttpErrorResponse): void {
        this.navigationService.navigateToLogin();
    }

    private handle403UnAuthorized(error: HttpErrorResponse): void {
        this.notifyService.warning(HTTP_403_ERROR_MESSAGE);
    }

    private handle404ServerError(error: HttpErrorResponse): void {
        this.notifyService.error(new Date(Date.now()).toLocaleString() + ': ' + HTTP_500_ERROR_MESSAGE);
    }

    private handle500ServerError(error: HttpErrorResponse): void {
        if(error.error.errorMessage !== undefined && error.error.errorMessage != null)
            this.notifyService.error(error.error.errorMessage);
        else
            this.notifyService.error(HTTP_500_ERROR_MESSAGE);
    }

    private handle422ServerError(error: HttpErrorResponse): void {
        this.notifyService.error(HTTP_422_ERROR_MESSAGE);
    }

    private handle475ServerError(error: HttpErrorResponse): void {
        this.notifyService.warning(error.error.errorMessage);
    }
}
