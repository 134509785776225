<ng-container *ngIf="formLoginEnabled">
    <div class="background mt-5">
        <div class="login-container">
            <div class="d-flex flex-row justify-content-center my-2">
                <img src=".../../../../../assets/images/logo-login.png"  class="login-logo" id="brandLogo" />
            </div>
            <form [formGroup]="lgForm" autocomplete="off" method="POST">
                <div class="ms-2 mt-5">
                    <div class="col-sm-6 my-3">
                        <label for="" class="title">Online ID*</label>
                    </div>
                    <div class="col-sm-12 my-3">
                        <input formControlName="loginId" type="text" class="input-text" (click)="markFormAsTouched()"
                            placeholder="Online Id">
                        <ng-container *ngIf="lgForm?.controls.loginId?.status =='INVALID' && lgForm.touched ">
                            <span class="error-message">Enter LoginId</span></ng-container>
                    </div>
                    <div class="col-sm-6 my-3">
                        <label for="" class="title">Password*</label>
                    </div>
                    <div class="col-sm-12 my-3">
                        <input formControlName="pwd" type="password"  (click)="markFormAsTouched()"
                            placeholder="Password" class=" input-text">
                        <ng-container *ngIf="lgForm?.controls.pwd?.status=='INVALID' && lgForm.touched ">
                            <span class="error-message">Enter Password</span></ng-container>
                    </div>
                    <div class="col-sm-10 my-3">
                        <input type="checkbox" formControlName="tremsAndCondition">
                        I agree to the GIR Tracker <a href="../../../assets/pdf/Website_Terms_2018_05_14.pdf"
                            target="_blank">Website Terms</a> and <a href={{privacy}} target="_new"
                            referrerpolicy="no-referrer">Privacy Policy</a>.
                        <ng-container
                            *ngIf="lgForm?.controls.tremsAndCondition?.status=='INVALID' && lgForm.touched ">
                            <br><span class="error-message">Select Terms and Conditions</span></ng-container>
                    </div>
                    <div class=" col-sm-12 d-flex flex-row my-3">
                        <div class="col-sm-6 me-1">
                            <button class="form-button"
                                [ngClass]=" lgForm?.status =='INVALID' && lgForm.touched ? 'invalid-button' : '' "
                                [disabled]="lgForm?.status =='INVALID'" (click)="formLogin()">Login </button>
                        </div>
                        <div class="col-sm-6 me-1">
                            <button class="form-button" (click)="showPopup($event)"> Reset Password </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <app-pop-up [dispPopUp]="dispPopup"  showfooter="false"[modelTitle]="resettitle" (closedPopUpEvent)="closePopup($event)">
        <div class="background">
            <form [formGroup]="rsForm"  autocomplete="off" method="POST">
                <div class="col-sm-12 my-4">
                    <label class="title-min" for="">Enter Email</label>
                </div>
                <div class="col-sm-10 my-4">
                    <input type="text" class="input-text-min" formControlName="eml" (click)="rsFormAsTouched()">
                    <ng-container *ngIf="rsForm.controls.eml?.status=='INVALID' && rsForm.touched ">
                        <br><span class="error-message">Enter Correct Email</span></ng-container>
                </div>
                <div class=" col-sm-12 d-flex flex-row my-5">
                    <div class="col-sm-6 me-1 ">
                        <button class="btn gir-button" (click)="resetPassword()"
                            [ngClass]=" rsForm?.status =='INVALID' && rsForm.touched ? 'invalid-button' : '' "
                            [disabled]="rsForm?.status =='INVALID' && rsForm.touched">Reset Password </button>
                    </div>
                    <div class="col-sm-6 me-1">
                        <button class="btn gir-button" (click)="closePopup()">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </app-pop-up>


    <app-pop-up [dispPopUp]="changePassPopup" showfooter="false" [modelTitle]="title" (closedPopUpEvent)="closeChangePassPopup($event)">
        <div class="background">
            <div class="col-sm-12">
                <p>Hi {{fn}} ! , please change Password below to access Deloitte's  GIR Tracker</p>
            </div>
            <form [formGroup]="cPForm" autocomplete="off" method="POST">                
                <div class="col-sm-12 my-3 ">
                    <label class="title-min" for="">Old Password</label>
                </div>
                <div class="col-sm-10 my-3">
                    <input type="password" class="input-text-min" formControlName="oldPass"
                        (click)="cPFormAsTouched()">
                    <ng-container *ngIf="cPForm?.controls.oldPass.status=='INVALID' && cPForm.touched ">
                        <br><span class="error-message">Enter old Password</span></ng-container>
                </div>

                <div class="col-sm-12 my-3 ">
                    <label class="title-min" for="">New Password</label>
                </div>
                <div class="col-sm-10 my-3">
                    <input type="password" class="input-text-min" formControlName="newPass"
                        [placeholder]="passwordPlaceholder" (click)="cPFormAsTouched()">
                    <ng-container *ngIf="cPForm.controls.newPass?.status=='INVALID' && cPForm.touched ">
                        <br><span class="error-message">Enter New Password</span></ng-container>
                </div>

                <div class="col-sm-12 my-3 ">
                    <label class="title-min" for="">Confirm New Password</label>
                </div>
                <div class="col-sm-10 my-3">
                    <input type="password" class="input-text-min" formControlName="confirmNewPass"
                    [placeholder]="passwordPlaceholder" (click)="cPFormAsTouched()">
                    <ng-container
                        *ngIf="cPForm.controls.confirmNewPass?.status=='INVALID' && cPForm.touched ">
                        <br><span class="error-message">Confim New Password</span></ng-container>
                </div>

                <div class="col-sm-10 my-3">
                    <input type="checkbox" formControlName="tremsAndCondition">
                    I agree to the GIR Tracker <a href="../../../assets/pdf/Website_Terms_2018_05_14.pdf"
                        target="_blank">Website Terms</a> and <a href={{privacy}} target="_new"
                        referrerpolicy="no-referrer">Privacy Policy</a>.
                    <ng-container
                        *ngIf="cPForm.controls.tremsAndCondition.status=='INVALID' && cPForm.touched ">
                        <br><span class="error-message">Select Terms and Conditions</span></ng-container>
                </div>
                <div class=" col-sm-12 d-flex flex-row mt-4">
                    <div class="col-sm-6 me-1">
                        <button class="btn gir-button" (click)="changePassword()"
                            [ngClass]=" cPForm?.status =='INVALID' && cPForm.touched ? 'invalid-button' : '' "
                            [disabled]="cPForm?.status =='INVALID' && cPForm.touched">Change Password </button>
                    </div>
                    <div class="col-sm-6 me-1">
                        <button class="btn gir-button" (click)="closeChangePassPopup()">Cancel</button>
                    </div>
                </div>                
            </form>
        </div>
    </app-pop-up>

  </ng-container>