import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { PrivacyComponent } from './footer/privacy/privacy.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UserIdleMonitoringComponent } from './user-idle-monitoring/user-idle-monitoring.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [
        HeaderComponent,
        PrivacyComponent,
        NotFoundComponent,
        UserIdleMonitoringComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule
    ],
    exports: [
        HeaderComponent,
        PrivacyComponent,
        SharedModule,
        UserIdleMonitoringComponent
    ]
})

export class LayoutModule { }