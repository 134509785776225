import { GirdColumn } from "./data-grid.model";
import { KeyValuePair, KeyValueSelection } from "./key-value-pair.model";
import { Jurisdiction } from "./tracker.models";

export const INVALID_USER_ACCESS_PAGE = '/invalid-user-access';
export const INVALID_USER_PAGE = '/invalid-user';
export const CONTACT_US = 'girtracker@deloitte.com';
export const PRIVACY = 'https://www2.deloitte.com/us/en/legal/privacy.html';
export const TOU = 'https://www2.deloitte.com/us/en/legal/terms-of-use.html';
export const COOKIE = 'https://cookienotice.deloitte.com/';
export const COPYRIGHT = 'Copyright © 2024 Deloitte Development LLC. All rights reserved.';
export const HTTP_500_ERROR_MESSAGE = 'The server encountered an internal error or misconfiguration and was unable to complete your request.';
export const HTTP_500_ERROR_TEXT = '500 Internal Server Error';
export const HTTP_403_ERROR_MESSAGE = 'You do not have permission to access this page';
export const HTTP_422_ERROR_MESSAGE = 'You do not have permission to access this page';
export const SLIDER_TIME_MS = 5000;
export const SLIDER_ITEMS_TO_ADD = 50;
export const SESSION_TIMEOUT_MESSAGE = "<h4>Your session is about to expire.</h4>";
export const SESSION_TIMEOUT_TITLE = 'Session Timeout';
export const CURRENT_TILE ='ctId';
export const PDF_EXTENSION ='.pdf';
export const WEB_LINK_01 ='http://';
export const WEB_LINK_02 ='https://';
export const NO_WEB_LINK_DOCUMENT ='_';
export const DETAILS_CONST ='detail';
export const DETAILS_CELL ='Details';
export const SELECT_VIEW_NAME= 'Enter View Name';
export const VIEW_SAVE_SUCCESS = 'View Saved Successfully.'
export const VIEW_SAVE_FAILURE = 'There is Issue . Please try again.'
export const VIEW_TITLE_ISSUE = 'Please Enter View Title.'
export const VIEW_DELETE_SUCCESS = 'View deleted Successfully.'
export const VIEW_DELETE_FAILURE = 'There is Issue in View Delete . Please try again.'
export const NO_ENTERPRISE_POLICY ='No Enterpise Policy for Specified Item'
export const NO_ATTACHMENT_AVAILABLE ='No Attachment(s) Available'
export const DELETE_SUCCESS = 'Deleted Successfully';
export const UPDATE_SUCCESS = 'Updated Successfully';
export const SAVE_SUCCESS = 'Saved Successfully';
export const SELECT_VIEW ='Please Select View to Delete';
export const QUESTION_INFO_FAILURE = 'Please Enter correct Information and try again.';
export const ANSWER_INFO_FAILURE = 'Please Enter correct Answer Information and try again.';
export const OPERATION_FAILURE = 'There is issue. please try again.';
export const QI_QDD_ERROR_MESSAGE_01 ='Please select Ownership and Withholding Responsibility and / or Jurisdiction.'
export const DEFAULT_KEY_VALUE_SELECTION_OBJ :  KeyValueSelection = {id : 0 , name : 'Select Value'}
export const DEFAULT_JURISDICTION_OBJ : Jurisdiction = {jurisdictionId : 0 , jurisdictionCode : 'Select Value' , jurisdictionName:'Select Value..'}
export const DEFAULT_EXCEL_NAME = 'Attachment.xlsx';
export const DEFAULT_PDF_NAME = 'Attachment.pdf';
export const DEFAULT_PDF_TITLE = 'Attachment';
export const DOWNLOAD_PROCESS_TEXT = 'The download process has started.';
export const DOWNLOAD_SAVE_TEXT = 'The File is being Prepared.';
export const DOWNLOAD_SUCCESS_TEXT = 'The File downloaded successfully.';
export const PDF_DISPLAY_PREPARE_TEXT = 'Preparing for download.';
export const IN_PROGRESS_MESSAGE = 'This feature is in progress';
export const DETAILS_GRID_COLUMN : GirdColumn = { id: 0 , columnTitle : DETAILS_CELL , isVisible : true ,isFilterable : false , filterCode : 'NF' ,columnField : DETAILS_CELL}
export const DOCUMENTS_NOT_AVAILABLE : string = 'Selected Item  do not have Links or Attachements';
export const NO_OPTION_FOR_SELECTION : string = 'No Option for Selection';
export const NEWS_ALERT_RELATIVE_URL : string = './newsalert';
export const DELETE_CONFIRMATION : string = 'Press \'Continue\' to delete Content...'
export const SELECT_CLIENT : KeyValuePair =  {id:0, name: 'Select Client..'}
export const POP_UP_MESSAGE_SELECT_BOTH_VIEW='Please select report from each section.'
export const POP_UP_MESSAGE_SELECT_SINGLE_VIEW='Please select at least on Report.'
export const AEOI_DASH_TITLE='AEOI Summary Dashboard'
export const HIGH_LEVEL_SUMMARY_TITLE :  string ='High Level Summary Dashboard';
export const NAVIGATION_TITLE :  string ='Navigation Support';
export const QI_DASHBOARD_TITLE :  string ='QI Dashboard';
export const REPORT_TITLE :  string ='Create Report';
export const REPORTING_DASH_TITLE :  string ='Reporting Dashboard';
export const SAVED_VIEW_TITLE :  string ='Saved Views';
export const SOURCE_DOC_TITLE :  string ='Source Documentation';
export const TITLE_TEXT :  string ='Title';
export const SEARCH_ALL_COLUMNS_TEXT :  string ='Search in all columns...';
export const VALUE_SEPERATOR_TEXT :  string ='||';
export const LOGIN_TEXT :  string ='Login Process Started..'
export const LOGIN_FAILURE_TEXT :  string ='Issue in Login Process . Please Enter Correct Login Id and Password';
export const PASSWORD_CHANGE_FAILURE ='Change Password Failure';
export const PASSWORD_CHANGE_SUCCESS ='Password Changed Successfully.'
export const PASSWORD_CONFIRMATION_CHECK='Please Confirm Password correctly.'
export const PASSWORD_RESET_SUCCESS ='Password Reset Successfully.';
export const PASSWORD_RESET_FAILURE ='Reset Password Failure';
export const PASSWORD_CHANGE_DESC='! , please change Password below to access Deloitte\'s  GIR Tracker'
export const PASSWORD_RESET_TITLE='Reset Password';
export const PASSWORD_CHANGE_TITLE='New Account ! Please change Password !';
export const PASSWORD_PLACEHOLDER='(Maximum 12 Character)';
export const DOC_GENERATE_STARTED='Process started. Please wait for sometime.';
export const COPY_RIGHT='Printed: Copyright ©2024 Deloitte Development LLC. All rights reserved.'
export const PDF_HEADER_INFO_EXCHANGE='GIR Tracker: Information Exchange (From Jurisdiction: {Jurisdiction}). Printed: {PrintDate}'
export const DOC_HEADER_NEWS_ALERT='GIR Tracker: News and Alerts. Printed: {PrintDate}'
export const DOC_HEADER_INFO_EXCHANGE='GIR Tracker: Information Exchange. Printed: {PrintDate}'
export const DOC_HEADER_REPORTING_DASHBOARD='GIR Tracker: Reporting Dashboard. Printed: {PrintDate}'
export const EXCEL_COLUMN_WIDTH ="900"
export const EXCEL_CELL_HEIGHT = 16
export const EXCEL_CELL_WIDTH = 30
export const SELECT_ITEM = 'Select item...'
export const EXCEL_FILE_EXTENTION = '.xlsx';
export const NEW_LINE_CHARACTER = '\n';
export const BR_TAG = '<br/>';













