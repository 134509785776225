import { Pipe, PipeTransform } from '@angular/core';
import { PDF_EXTENSION } from 'src/app/core/model/constants';

@Pipe({
  name: 'displayPdfIcon',
  pure : true
})
export class DisplayPdfIconPipe implements PipeTransform {

  transform(val: string): boolean {
    if(val?.length>0)
      return val.toLocaleLowerCase().includes(PDF_EXTENSION)  
    
    return false
  }
}
