import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class InformationExchangeService extends BaseService {
  private readonly GET_INFORMATION_JURISDICTIONS_URL = this.generateAPIUrl('InformationExchange/GetInformationExchangeJurisdictions');
  private readonly GET_INFORMATION_BY_JURISDICTION_URL = this.generateAPIUrl('InformationExchange/GetInformationExchangeRelationships');

  constructor(injector: Injector) {
    super(injector);
  }

  public getInformationJurisdictions(): Observable<any> {
    return this.http.post<any>(this.GET_INFORMATION_JURISDICTIONS_URL, null);
  }

  public getInformationByJurisdiction(payload: any): Observable<any> {
    return this.http.post<any>(this.GET_INFORMATION_BY_JURISDICTION_URL, payload);
  }

}


