import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SavedViewsService extends BaseService {
  private readonly GetSavedViewTrackerURL = this.generateAPIUrl('Dashboard/GetSavedViewTrackerList');
  private readonly GetSavedViewTrackerForReportURL = this.generateAPIUrl('CRSFatca/GetSavedViewReportingList');
  private readonly GetSavedViewTrackerDataURL = this.generateAPIUrl('Dashboard/GetSavedViewDataNew');
  private readonly GetSavedViewTrackerData_QIQDD_URL = this.generateAPIUrl('QIQDD/GetSavedViewTrackerData');
  private readonly SavedViewDeleteURL = this.generateAPIUrl('Dashboard/DeleteSavedViewTracker');

  constructor(private injector: Injector) {
    super(injector)
  }

  public getSavedViewTrackerList(payload: any): Observable<any> {
    return this.http.post<any>(this.GetSavedViewTrackerURL, payload);
  }
  public getSavedViewTrackerListForReport(payload: any): Observable<any> {
    return this.http.post<any>(this.GetSavedViewTrackerForReportURL, payload);
  }

  public getSavedViewTrackerData(payload: any): Observable<any> {
    return this.http.post<any>(this.GetSavedViewTrackerDataURL, payload);
  }

  public getSavedViewTrackerDataQIQDD(payload: any): Observable<any> {
    return this.http.post<any>(this.GetSavedViewTrackerData_QIQDD_URL, payload);
  }

  public deleteSavedViewTrackerData(payload: any): Observable<any> {
    return this.http.post<any>(this.SavedViewDeleteURL, payload);
  }
}
