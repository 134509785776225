import { Component } from '@angular/core';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-section-layout',
  templateUrl: './section-layout.component.html',
  styleUrls: ['./section-layout.component.scss']
})
export class SectionLayoutComponent {

  toggleSidebar: boolean | undefined;
  constructor(private commonDataService: CommonDataService) {
  }

  ngOnInit() {
    this.commonDataService.sideBarToggle$.subscribe(x => this.toggleSidebar = x)
  }
}
