import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class NavigationService {
    private rootUrl: string = '/';

    constructor(private router: Router) {

    }

    public navigateToUrl(url: string) {
        this.router.navigateByUrl(url);
    }

    public navigateToLogin() {
        this.router.navigateByUrl('/login');
    }

}