import { Component, Input } from '@angular/core';
import { OPERATION_FAILURE, SAVE_SUCCESS } from 'src/app/core/model/constants';
import { TileServiceService } from 'src/app/core/services/tile-service.service';

@Component({
  selector: 'app-enterprise-policy-save',
  templateUrl: './enterprise-policy-save.component.html',
  styleUrls: ['./enterprise-policy-save.component.scss']
})
export class EnterprisePolicySaveComponent {
  @Input() dataElementId: number | undefined
  @Input() jurisdictionId: number | undefined
  @Input() tileService: TileServiceService

  public eventMessage: string | undefined
  public enterprisePolicyString: string | undefined


  public publishEnterPrisePolicyAPI() {
    let payload = {
      "content": this.enterprisePolicyString,
      "dataElementId": this.dataElementId,
      "jurisdictionId": this.jurisdictionId
    }
    this.tileService.SaveEnterprisePolicy(payload)
      .subscribe(resp => {
        this.eventMessage = SAVE_SUCCESS
      },error => this.eventMessage = OPERATION_FAILURE);
  }

}
