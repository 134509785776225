import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LayoutService {
    private sidebar$ = new BehaviorSubject(false);
    public hasSidebar$ = new BehaviorSubject(false);
    public sidebarStage$ = this.sidebar$.asObservable();
    public sidebarUserStage$ = this.hasSidebar$.asObservable();

    constructor() { }

    get sidebarChange(): Observable<boolean> {
        return this.sidebar$.asObservable();
    }

    public toggleSidebar(): void {
        this.sidebar$.next(!this.sidebar$.value);
    }

    public setSidebar(status: boolean): void {
        this.sidebar$.next(status);
    }

}
