import { Component } from '@angular/core';
import { Subject  } from 'rxjs';
import { KeyValuePair,  } from '../core/model/key-value-pair.model';
import { DashboardService } from '../core/services/dashboard.service';
import { NavigationService } from '../core/services/navigation.service';
import { CONTACT_US, INVALID_USER_ACCESS_PAGE } from '../core/model/constants';
import { NewsAlert } from '../core/model/news-alert.model';
import { NewsType } from '../core/model/enum.model';
import { CommonDataService } from '../core/services/common-data.service';
import { DashboardTile, DeadLine, TileCode } from '../core/model/dashboard.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  
  public dashboardTiles : DashboardTile[] = [] ;
  public upcomingDeadLines : DeadLine[] = [] ;
  public newsAlerts : NewsAlert[] = [] ;
  public newsAndAlertAttachmentList : KeyValuePair[] = [] ;
  public contactUs: string = CONTACT_US;
  public contactString : string = `{mailto: ${this.contactUs}}`
   
  
  public newsAlertsDash$ = new Subject<NewsAlert[]>() ;
  public newsAlterIndex : number =0;
  public endIndex : number =0

  public displayStyle : boolean = false
  public popupTitle : string|false=''
    
  public popedUpNewsAlert : NewsAlert 
  public get NewsType(): typeof NewsType {return NewsType; }
 
  constructor(private dashboardService: DashboardService,
     private navigationService: NavigationService
     ,private commonDataService : CommonDataService) { 


    this.getDashboardTilesDetail();
  }


  handleClick(tile : KeyValuePair){   
    if(tile.id == TileCode.CRSFatca || tile.id == TileCode.QIQDD)
      this.commonDataService.selectedTile$.next(tile);
  }


  private getDashboardTilesDetail(): void {
    this.dashboardService.getDashboardTilesDetail().subscribe(resp => {
      this.dashboardTiles = [...resp].sort((a,b)=> a.displayOrder - b.displayOrder);
    },
    (error) => {
      if (error.status === 422) {
        this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
      }
    });
  }

}
