import { Component, ViewChild } from '@angular/core';
import {  DOC_HEADER_REPORTING_DASHBOARD, REPORTING_DASH_TITLE } from 'src/app/core/model/constants';
import { TileCode } from 'src/app/core/model/dashboard.model';
import { GIRGridCellEvent, GirdColumn } from 'src/app/core/model/data-grid.model';
import { KeyValuePair, KeyValueSelection } from 'src/app/core/model/key-value-pair.model';
import { NotifyService } from 'src/app/core/services/notify.service';
import { ReportingDashboardService } from 'src/app/core/services/reporting-dashboard.service';
import { DataGridComponent } from 'src/app/shared/common/data-grid/data-grid.component';

@Component({
  selector: 'app-reporting-dashboard',
  templateUrl: './reporting-dashboard.component.html',
  styleUrls: ['./reporting-dashboard.component.scss']
})
export class ReportingDashboardComponent {

  public title: string = REPORTING_DASH_TITLE
  public showLastupdated: boolean = false;
  public faicon: string = 'far fa-file-chart-line'
  public columnName: GirdColumn[] = [];
  public selectedjurisdiction: KeyValuePair;
  public displayStyle: boolean = false;
  public popupTitle: string | false = 'Please Select Required Columns'
  public elements: Object[] = []
  public elementsMeta: Object[] = []
  public visibleColumns: GirdColumn[] = []
  public jurisdictionList: KeyValuePair[] = []
  public popupSpecificTitle: string | undefined
  public cellDescription: string | undefined
  public displayAttachments: boolean = false
  public docList: KeyValueSelection[] = []
  public displayDoc: boolean = false
  public showAll : boolean = false
  public hideAll : boolean = false
  public docTitle : string = DOC_HEADER_REPORTING_DASHBOARD.replace('{PrintDate}',(new Date()).toDateString()); 
  public get TileCode(): typeof TileCode { return TileCode; }
  @ViewChild('reportingGrid') reportingGrid: DataGridComponent;

  constructor(private reportingDashboardService: ReportingDashboardService,
    private notifyService: NotifyService) { }

  ngOnInit() {
    this.accessReportData();
  }

  async accessReportData() {
    this.reportingDashboardService.getReportData().subscribe(async x => {
      this.elements = x.Datatable;
      this.jurisdictionList = x.JurisdictionTable;
      if (this.columnName.length == 0) {
        this.columnName = []
        x.ColumnList.forEach(u => {
          if (u.isVisible)
            this.columnName.push({
              id: u.id, columnTitle: u.columnName, isVisible: u.isVisible
              , isFilterable: u.isFilterable, filterCode: u.filterCode, columnField: u.columnDBName
            })
        })
      }
      this.showAll = true;
      this.visibleColumns = this.columnName.filter(x => x.isVisible == true)
      this.elementsMeta = this.visibleColumns;
    })
  }

  async refreshGridColumn() {
    this.elementsMeta = this.columnName.filter(x => x.isVisible == true);
  }

  public handleTopicClick(value: any) {
    this.displayStyle = true
  }

  public handleCellClickEvent(item: GIRGridCellEvent) {
    this.popupSpecificTitle = item.columnName;
    this.cellDescription = item.cellDescription;
    if (item.columnName == 'Details (SOPs)' || item.columnName == 'Details') {
      this.selectedjurisdiction = this.jurisdictionList.filter(x => x.name == item.jurisdictionName)[0];
      let payload = { jurisdictionId: this.selectedjurisdiction.id }
      this.reportingDashboardService.getReportAttachmentFileData(payload).subscribe(x => {
        this.docList = x
        this.displayAttachments = true
      })
    }
    this.displayDoc = true
  }

  public closeDocPopup(val: any) {
    this.displayDoc = false;
    this.displayAttachments = false;
    this.cellDescription = undefined
    this.popupSpecificTitle = undefined

  }

  public closePopup(val: any) {
    this.displayStyle = false
    this.refreshGridColumn()
  }

  public showPopup() {
    this.displayStyle = false
  }

  public  handleColumnSelection(item: GirdColumn) {
    item.isVisible = !item.isVisible;
    if(this.visibleColumns.find(x=>x.isVisible== false))
        this.showAll = false;
    else
       this.showAll= true
      
    if(this.visibleColumns.find(x=>x.isVisible== true))
       this.hideAll = false;
     else
       this.hideAll = true;

  }

  public handleSelection(showAll : boolean) {
    if(showAll)
     { this.showAll = true;
       this.hideAll = false;
       this.visibleColumns.forEach(x=>x.isVisible=true)
     }
    else
     {  this.visibleColumns.forEach(x=>x.isVisible=false);
        this.visibleColumns.find(x=>x.columnTitle=='Country').isVisible= true;
        this.showAll = false;
        this.hideAll = true;
     }
    this.refreshGridColumn()
  }

  public excelEvent() {
    this.reportingGrid.exportToExcel();
  }

}