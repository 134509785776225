import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/layout/not-found/not-found.component';
import { InvalidUserComponent } from './shared/layout/invalid-user/invalid-user.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './core/guards/auth.guard';
import { WorkplaceComponent } from './workplace/workplace/workplace.component';
import { InformationExchangeComponent } from './workplace/information-exchange/information-exchange.component';
import { ReportingDashboardComponent } from './workplace/reporting-dashboard/reporting-dashboard.component';
import { NewsAlertComponent } from './workplace/news-alert-component/news-alert-component';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = 
[
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { preload: true }
  }, 
  {
    path:'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard], 
  },
  {
    path: 'login',
    component :  LoginComponent,
    loadChildren :()=> import('./auth/auth.module').then(m=>m.AuthModule),
  },
  {
    path: 'logout',
    component: LogoutComponent,
    loadChildren :()=> import('./auth/auth.module').then(m=>m.AuthModule),
  },
  {
    path: 'invalid-user',
    component: InvalidUserComponent
  },
 
  {
    path:'newsalert',
    component: NewsAlertComponent,
    canActivate: [AuthGuard], 
  },
  {
    path:'reportingdashboard',
    component: ReportingDashboardComponent,
    canActivate: [AuthGuard], 
  },
  {
    path:'informationexchange',
    component: InformationExchangeComponent,
    canActivate: [AuthGuard], 
  },
  {
    path:'workplace',
    component :  WorkplaceComponent,
    loadChildren :()=> import('./workplace/workplace.module').then(m=>m.WorkplaceModule),
    canActivate: [AuthGuard], 
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
  
  @NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }
      )],
    exports: [RouterModule]
  })
export class AppRoutingModule { }

