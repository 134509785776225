import { Component } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent {
  public dispPopUp: boolean = true  
  public fileSafeUrl : SafeUrl 
  public fileName : string | undefined
  public fileTitle : string | undefined
  public fileLink : string | undefined

  public closePopUp(val: any){
    this.dispPopUp = false;
  }

  public openFile(val : any){
    window.open(this.fileLink,'target','');
    window.focus()
  }

}
