<div class="" [ngStyle]="{'display' : (menuItem?.id===parentId) && toggleListSection ? 'block' : 'none' }">
    <ul class="sublevel-nav-new">
        <li class="sublevel-nav-item " *ngFor="let item of menuItem?.children">
            <div class="sublevel-nav-link">

                <span class="me-1 pe-2" (click)="expandChildMenu(item)">
                    <ng-container *ngIf="(item.children && item.children.length>0)">
                        <span *ngIf="((selectedId == item.id) && toggeleChild ) ">
                            <em class="fas fa-angle-down fa-1x "></em></span>
                        <span *ngIf="!((selectedId == item.id) && toggeleChild)">
                            <em class="fas fa-angle-right fa-1x"></em></span>
                    </ng-container>
                </span>

                <span (click)="markSelfAndChildrenAsSelected(item,!item.isSelected!)"
                    (click)="handleSingleSelect(item)">
                    <span *ngIf="item.isSelected && showSelectedSquare"><em class="fal fa-check-square"></em></span>
                    <span *ngIf="!item.isSelected && showSelectedSquare"><em class="fal fa-square"></em></span>
                    <span class="m-1 p-1 " title="{{item.name}}" style="overflow: hidden; text-overflow: ellipsis;">
                        {{item.name}}
                    </span>
                </span>

            </div>
            <div *ngIf=" item.children && item.children.length>0">
                <app-child-menu [toggleListSection]="toggeleChild" [menuItem]="item" [parentId]="selectedId"
                    (checkParentToMarkAsSelected)="isChildrenSelected(item,$event)">
                </app-child-menu>
            </div>
        </li>
    </ul>
</div>