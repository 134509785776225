import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent {
  @Output() closedPopUpEvent = new EventEmitter<string>();
  @Input() dispPopUp : boolean = false
  @Input() modelTitle : string | undefined;
  @Input() displayHeaderSection : boolean = true 
  @Input() showfooter : boolean = true 
  
  public closePopup(){
    this.closedPopUpEvent.emit('none');
  }
  
}
