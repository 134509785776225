
export class KeyValuePair {
    public id : number | undefined;
    public name : string | undefined;
  }


export class KeyValueSelection extends KeyValuePair {
    public isItemselected? : boolean = false
    public routerLink? : string | undefined
  }  

export class KeyValue{
  public key? : string | undefined;
  public value? : string | number | undefined|boolean | undefined;
}

  
 