import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { DOCUMENTS_NOT_AVAILABLE, DOWNLOAD_PROCESS_TEXT, INVALID_USER_ACCESS_PAGE, PDF_DISPLAY_PREPARE_TEXT, PDF_EXTENSION } from 'src/app/core/model/constants';
import { TileCode } from 'src/app/core/model/dashboard.model';
import { KeyValueSelection } from 'src/app/core/model/key-value-pair.model';
import { DownloadService } from 'src/app/core/services/download.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NotifyService } from 'src/app/core/services/notify.service';

@Component({
  selector: 'app-file-link-display',
  templateUrl: './file-link-display.component.html',
  styleUrls: ['./file-link-display.component.scss']
})
export class FileLinkDisplayComponent {
  @Input() tileType: number = 0
  @Input() trackerCode: number = 0
  @Input() docList: KeyValueSelection[] = []
  @ViewChild('downloadFile', { read: ViewContainerRef }) private viewRef!: ViewContainerRef
  noDocumentMessage: string = DOCUMENTS_NOT_AVAILABLE

  constructor(private downloadService: DownloadService
    , private notifyService: NotifyService
    , private navigationService: NavigationService) {

  }

  public downloadReportingDashboard(item: KeyValueSelection) {
    if (this.tileType == TileCode.ReportingDashboard)
      this.downloadDetailFile_ReportDashoBoard(item);
    else if (this.tileType == TileCode.NewsAndAlerts)
      this.downloadDetailFile_NewsAndAlerts(item);
    else if (this.tileType == TileCode.CRSFatca)
      this.downloadDetailFile_CrsFatca(item);
  }

  public downloadDetailFile_ReportDashoBoard(item: KeyValueSelection) {
    this.notifyService.info(PDF_DISPLAY_PREPARE_TEXT);
    this.downloadService.GetReportingDashboardFile(item.id)
    .subscribe(resp => { 
      this.downloadService.loadFileDownload(this.viewRef, resp, item.name);
    },
      (error) => {
        if (error.status === 422) {
          this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
        }
      });
  }

  public downloadDetailFile_NewsAndAlerts(item: KeyValueSelection) {
    this.notifyService.info(PDF_DISPLAY_PREPARE_TEXT);
    this.downloadService.getNewsAttachmentFile({ attachmentId: item.id })
    .subscribe(resp => {
      this.downloadService.loadFileDownload(this.viewRef, resp, item.name);
    },
      (error) => {
        if (error.status === 422) {
          this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
        }
      });
  }

  public downloadDetailFile_CrsFatca(item: KeyValueSelection) {
    this.notifyService.info(PDF_DISPLAY_PREPARE_TEXT);
    this.downloadService.getCrsFatcaAttachment({ attachmentId: item.id })
    .subscribe(resp => {
      this.downloadService.loadFileDownload(this.viewRef, resp, item.name);
    },
      (error) => {
        if (error.status === 422) {
          this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
        }
      });
  }

} 
