import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'src/app/core/model/tracker.models';
import { CommonDataService } from 'src/app/core/services/common-data.service';

@Component({
  selector: 'app-child-menu',
  templateUrl: './child-menu.component.html',
  styleUrls: ['./child-menu.component.scss']
})
export class ChildMenuComponent {

  @Input() menuItem: MenuItem | undefined
  @Input() toggleListSection: boolean | undefined
  @Input() parentId: number = 0;
  @Input() showSelectedSquare: boolean = true
  @Output() checkParentToMarkAsSelected = new EventEmitter<boolean>();
  @Output() updateSubcategoryDataEvent = new EventEmitter<boolean>();
  selectedId: number = 0
  isSelected: boolean = false
  toggeleChild: boolean = false

  constructor(private commonDataService: CommonDataService) { }

  ngOnInit() { }

  public expandChildMenu(id: MenuItem) {
    if (id.children && id.children.length > 0) {
      this.selectedId = id.id
      this.toggeleChild = !this.toggeleChild;
    }
  }

  public markSelfAndChildrenAsSelected(item: MenuItem, isMarked: boolean) {
    item.isSelected = isMarked;
    if (item.children && item.children.length > 0)
      item.children.forEach(x => this.markSelfAndChildrenAsSelected(x, isMarked))

    this.checkParentToMarkAsSelected.emit(isMarked);
    this.updateSubcategoryDataEvent.emit(isMarked);
  }

  public isChildrenSelected(item: MenuItem, value: any) {
    let val = this.isAllChilderenSelectedFunc(item);
    item.isSelected = val;
    this.checkParentToMarkAsSelected.emit(val);
    this.updateSubcategoryDataEvent.emit(val)
  }

  public isAllChilderenSelectedFunc(item: MenuItem): boolean {
    if (item.children!.length == 0)
      return item.isSelected!;
    let returnedvalue: boolean = true
    item.children!.forEach(x => {
      const status = this.isAllChilderenSelectedFunc(x)
      returnedvalue = returnedvalue && status
    }
    )
    return returnedvalue;
  }

  public handleSingleSelect(item: any) {
    this.commonDataService.singleSelectedItem$.next(item.id);
  }

}
