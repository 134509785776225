import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './layout/footer/footer.component';
import { RouterModule } from '@angular/router';
import{ FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { LayoutContainerComponent } from './layout/layout-container/layout-container.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PageContentComponent } from './layout/page-content/page-content.component';
import { PopUpComponent } from './common/pop-up/pop-up.component';
import { ProgressPopupComponent } from './common/progress-popup/progress-popup.component';
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { ExcelModule, GridModule, PDFModule } from "@progress/kendo-angular-grid";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DataGridComponent } from './common/data-grid/data-grid.component';
import { MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { DatagridButtonsComponent } from './common/datagrid-buttons/datagrid-buttons.component';
import { CustomDropDownListFilterComponent } from './common/custom-drop-down-list-filter/custom-drop-down-list-filter.component';
import { MultiCheckFilterComponent } from './common/multi-check-filter-component/multi-check-filter-component';
import { DisplayDetailsIconPipe } from './common/display-details-icon.pipe';
import { DisplayPdfIconPipe } from './common/display-pdf-icon.pipe';
import { GetAnswerPipe } from './common/get-answer.pipe';
import { CrsFatcaGridComponent } from './common/crs-fatca-grid/crs-fatca-grid.component';
import { QiQddGridComponent } from './common/qi-qdd-grid/qi-qdd-grid.component';
import { NewsAlertSiderbarComponent } from './common/news-alert-siderbar/news-alert-siderbar.component';
import { FileLinkDisplayComponent } from './common/file-link-display/file-link-display.component';
import { EnterprisePolicySaveComponent } from './common/enterprise-policy-save/enterprise-policy-save.component';
import { DisplayLinkPipe } from './common/display-link.pipe';
import {NgxMarkjsModule} from 'ngx-markjs';
import { ProcessCellDataPipe } from './common/process-cell-data.pipe';
import { DropDownListFilterComponent } from './common/drop-down-list-filter/drop-down-list-filter.component';
import { OssCopyrightComponent } from './layout/footer/oss-copyright/oss-copyright.component';
import { DownloadFileComponent } from './common/download-file/download-file.component';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { PagerModule } from "@progress/kendo-angular-pager";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { PrintGridComponent } from './common/print-grid/print-grid.component';
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { ToInnerHtmlCompatiblePipe } from './common/to-inner-html-compatible.pipe';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    FontAwesomeModule,
    NotificationModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    DropDownsModule,
    MultiSelectModule,
    ExcelModule,
    PDFModule,
    NgxMarkjsModule,
    ListViewModule,
    PagerModule,
    CommonModule,
    IndicatorsModule,
    ProgressBarModule
  ],
  declarations: [
    FooterComponent,
    LayoutContainerComponent,
    PageContentComponent,
    PopUpComponent,
    ProgressPopupComponent,
    DataGridComponent,
    MultiCheckFilterComponent,
    CustomDropDownListFilterComponent,
    DatagridButtonsComponent,
    DisplayDetailsIconPipe,
    DisplayPdfIconPipe,
    GetAnswerPipe,
    CrsFatcaGridComponent,
    QiQddGridComponent,
    NewsAlertSiderbarComponent,
    FileLinkDisplayComponent,
    EnterprisePolicySaveComponent,
    DisplayLinkPipe,
    ProcessCellDataPipe,
    DropDownListFilterComponent,
    OssCopyrightComponent,
    DownloadFileComponent,
    PrintGridComponent,
    ToInnerHtmlCompatiblePipe,
  ],
  exports: [
    RouterModule,
    FooterComponent,
    FormsModule,
    ReactiveFormsModule,
    LayoutContainerComponent,
    NotificationModule ,
    PageContentComponent,
    PopUpComponent,
    ProgressPopupComponent,    
    ButtonsModule,
    LabelModule,
    InputsModule,
    GridModule,
    ListViewModule,
    DropDownsModule,
    DataGridComponent,
    MultiSelectModule,
    MultiCheckFilterComponent,
    CustomDropDownListFilterComponent,
    DatagridButtonsComponent,
    DisplayPdfIconPipe,
    GetAnswerPipe,
    CrsFatcaGridComponent,
    QiQddGridComponent,
    NewsAlertSiderbarComponent,
    FileLinkDisplayComponent,
    NgxMarkjsModule,
    DropDownListFilterComponent,
    OssCopyrightComponent,
    DownloadFileComponent,
    ToInnerHtmlCompatiblePipe
  ]
})

export class SharedModule { }
