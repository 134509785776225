import { Component, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/model/user.model';
import { AuthService } from 'src/app/core/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnDestroy {
  public homePageUrl:string = '/';
  public authUser$: Observable<User>;

  constructor(private authService: AuthService) {
    this.authUser$ = this.authService.authUser$;
  }

  public logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void { }

  }
