import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Router, RoutesRecognized } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class AppPreloadingStrategy implements PreloadingStrategy {
    public preloadRoutes = [];
    constructor(private router: Router) {
        this.router.events
            .pipe(
                filter(event => event instanceof RoutesRecognized),
            ).subscribe((data: RoutesRecognized) => {
                if (data.state.root.firstChild.data.preloadModules) {
                    this.preloadRoutes = data.state.root.firstChild.data.preloadModules;
                }
            });
    }

    preload(route: Route, load): Observable<any> {
        return this.preloadRoutes.includes(route.path) ? load() : of(null);
    }
}