<kendo-dialog *ngIf="showProgressPopup" (close)="close()" class="pop-up">
  <span class="header row message-heading" [innerHTML]="progressTitle | safeHtml"></span>
  <br />
  <br />
  <div class="d-flex flex-column progress">
    <div class="d-flex flex-column election-message" [innerHTML]="progressMessage | safeHtml"></div>
    <br />
    <br />
    <div class="row action">
      <ng-content></ng-content>
    </div>
  </div>
</kendo-dialog>