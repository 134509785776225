export class TrackerSections {
  id: number | undefined;
  menuId?: number | undefined;
  name: string | undefined;
  templateId: string | undefined;
  children: MenuItem[] = [];
  routerLink?: string | undefined;
  isSelected?: boolean | undefined = false;
  trackerType?: number = 1;
  menuCode?: string | undefined
  isMultiselect?: boolean | undefined = false;
  imageName?: string | undefined;
}

export class MenuItem {
  id: number | undefined;
  name: string | undefined;
  children?: MenuItem[] | undefined;
  expanded?: boolean | undefined;
  isSelected?: boolean | undefined = false
  imageName?: string | undefined;
  isMultiselect?: boolean | undefined = false;
}

export class SubCategory {
  public subCategoryId: number;
  public subCategoryName: string;
  public categoryId: number;
  public categoryName: string;
}

export class Jurisdiction {
  public jurisdictionId: number | undefined;
  public jurisdictionName: string | undefined;
  public jurisdictionCode: string | undefined;
  public isItemSelected?: boolean = false
};

export class HighLevelSummary {
  ColumnList: string[] | undefined;
  Datatable: Object[] | undefined;
}

export class AEOIGridEvent {
  public IDAEOIReporting?: number | undefined
  public FKCountryID?: number | undefined
  public Country?: number | undefined
  public DocumentTitle?: number | undefined
  public DocumentName?: number | undefined
}

export enum TrackerCode {
  CRS = 'CRS',
  FATCA = 'FATCA',
  SAVED_VIEW = 'SV',
  QI_TRACKER = 'QIT'
}