import { Component, OnInit } from '@angular/core';
import { CookieConsentService } from './core/services/cookie-consent.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private cookieConsentService: CookieConsentService) { }

  public ngOnInit(): void {
    this.cookieConsentService.init();
   }

}