import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class NotifyService {
    public defaultOptionsForError = {
        cssClass: 'button-notification',
        animation: { type: 'fade', duration: 500 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { icon: false },
        closable: true,
        hideAfter: 30000
    }
    public defaultOptionsSuccess = {
        cssClass: 'button-notification',
        animation: { type: 'fade', duration: 500 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { icon: false },
        hideAfter: 5000,
    }
    public errorNotification = false;
    public warningNotification = false;
    public errorNotificationInstance;
    public warningNotificationInstance;

    constructor(private notificationService: NotificationService) {
    }

    public info(message: string, options?: any) {
        this.notificationService.show({ content: message, ...this.defaultOptionsSuccess, ...{ ...{ type: { style: 'info' } } }, ...options });
    }

    public warning(message: string, options?: any) {
        if (!this.warningNotification) {
            this.warningNotificationInstance = this.notificationService.show({ content: message, ...this.defaultOptionsForError, ...{ ...{ type: { style: 'warning' } } }, ...options });
            this.warningNotification = true;
            var myTimeout = setTimeout(() => {
                if (this.warningNotification) {
                    this.warningNotificationInstance.hide();
                }
            }, 30000);
            this.warningNotificationInstance.afterHide.subscribe(() => { clearTimeout(myTimeout); this.warningNotification = false });
        }

    }
    public error(message: string, options?: any) {
        if (!this.errorNotification) {
            this.errorNotificationInstance = this.notificationService.show({ content: message, ...this.defaultOptionsForError, ...{ ...{ type: { style: 'error' } } }, ...options });
            this.errorNotification = true;
            var myTimeout = setTimeout(() => {
                if (this.errorNotification) {
                    this.errorNotificationInstance.hide();
                }
            }, 30000);
            this.errorNotificationInstance.afterHide.subscribe(() => { clearTimeout(myTimeout); this.errorNotification = false });
        }

    }
    public success(message: string, options?: any) {
        this.notificationService.show({ content: message, ...this.defaultOptionsSuccess, ...{ ...{ type: { style: 'success' } } }, ...options });
    }

}

