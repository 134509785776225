import { Pipe, PipeTransform } from '@angular/core';
import { DETAILS_CONST } from 'src/app/core/model/constants';

@Pipe({
  name: 'displayDetailsIcon',
  pure: true
})
export class DisplayDetailsIconPipe implements PipeTransform {
  transform(val: string): boolean {
    if (val?.length > 0)
      return val.toLocaleLowerCase().includes(DETAILS_CONST)

    return false
  }
}
