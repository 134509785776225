import { KeyValueSelection } from "./key-value-pair.model";

export class DeadLine {
    id? : number | undefined;
    deadlineText? : string | undefined;
    deadlineDate? : string | undefined;
    deadlineType? : string | undefined;
    deadlineURL? : string | undefined;
    countryId? : number | undefined;
  }

  export class DeadLineForSlider {
    id? : number | undefined;
    deadlineText? : string | undefined;
    deadlineDate? : string | undefined;
  }

export enum TileCode{
        CRSFatca = 1,
        InformationExchange = 2,
        QIQDD = 3,
        NewsAndAlerts = 4,
        ReportingDashboard = 5
  }

export class DashboardTile extends KeyValueSelection {
      public displayOrder? : number | undefined
      public isDisplay? : boolean | undefined
      public routerLink? : string | undefined
}