import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { KeyValuePair } from '../model/key-value-pair.model';
import { DashboardTile } from '../model/dashboard.model';

@Injectable({
  providedIn: 'root'
})

export class DashboardService extends BaseService {
  private readonly GET_DASHBOARD_TILES_URL = this.generateAPIUrl('Dashboard/GetDashboardTiles');
  private readonly GET_UPCOMING_DEALLINE_URL = this.generateAPIUrl('Dashboard/GetUpcomingDeadlinesSlider');
  private readonly GET_UPCOMING_DEALLINE_SLIDER_URL = this.generateAPIUrl('Dashboard/GetUpcomingDeadlinesSlider');
  private readonly GET_LEFT_SIDE_MENU_URL = this.generateAPIUrl('Dashboard/GetLeftSideMenu');

  router: any;

  constructor(injector: Injector) {
    super(injector);
  }

  public getDashboardTilesDetail(): Observable<DashboardTile[]> {
    return this.http.post<any>(this.GET_DASHBOARD_TILES_URL, null);
  }


  public getUpcomingDeadlineDetail(): Observable<KeyValuePair[]> {
    return this.http.post<any>(this.GET_UPCOMING_DEALLINE_URL, null);
  }

  public getUpcomingDeadlineDetailSlider(): Observable<DashboardTile[]> {
    return this.http.post<any>(this.GET_UPCOMING_DEALLINE_SLIDER_URL, null);
  }

  public getLeftSideMenuDetail(payload :any): Observable<any[]> {
    return this.http.post<any>(this.GET_LEFT_SIDE_MENU_URL,payload);
  }
}