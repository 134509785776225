
    export enum NewsType
    {
        Unknown = -1,
        Link = 1,
        Attachment = 2,
        Announcement = 3
    }

    export enum GIRUserRole
    {
        DU = 1,     //Deloitte User
        DTA = 2,    //Deloitte Tax Administrator
        DA = 3,     //Deloitte Administrator
        CU = 4,     //Client User
        CTM = 5,    //Client Tax Manager
        CSU = 6,    //Client Super User
        SA = 7,     //Super Administrator
        DLU = 8     //Deloitte Limited User
    }
