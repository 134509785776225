import { Pipe, PipeTransform } from '@angular/core';
import { NO_WEB_LINK_DOCUMENT, PDF_EXTENSION, WEB_LINK_01, WEB_LINK_02 } from 'src/app/core/model/constants';
import { cellDataPipeResult } from 'src/app/core/model/data-grid.model';

@Pipe({
  name: 'processCellData'
})
export class ProcessCellDataPipe implements PipeTransform {

  transform(value: string): cellDataPipeResult | null {
    if (value?.length == 0)
      return null;
    
    let dataArray = value?.split('||');
    if (dataArray?.length == 1) {
      let valueString: string = dataArray[0];
      return { type: NO_WEB_LINK_DOCUMENT, displayValue: valueString, linkString: '' };
    }
    
    if (dataArray?.length > 1) {
      let valueString: string = dataArray[0];
      valueString = valueString?.replace('||', '');
      let linkString: string = dataArray[1];
      if (value?.toLocaleLowerCase().includes(PDF_EXTENSION))
        return { type: PDF_EXTENSION, displayValue: valueString, linkString: linkString };
      else if (value?.toLocaleLowerCase().includes(WEB_LINK_01) || value?.toLocaleLowerCase().includes(WEB_LINK_02))
        return { type: WEB_LINK_01, displayValue: valueString, linkString: linkString };
      else
        return { type: NO_WEB_LINK_DOCUMENT, displayValue: valueString, linkString: linkString };
    }
    
    return null
  }
}
