import { Component, Input } from '@angular/core';
import { TITLE_TEXT } from 'src/app/core/model/constants';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent {
  @Input() title: string = TITLE_TEXT
  @Input() lastUpdatedDate: string = Date()
  @Input() showLastupdated: boolean = false
  @Input() faicon: string = 'far fa-paste'

}
