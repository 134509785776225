import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormLoginService extends BaseService {

  private readonly RESET_PASSWORD_AND_SEND_EMAIL= this.generateAPIUrl('User/ResetPasswordAndSendEmail');
  private readonly CHANGE_PASSWORD= this.generateAPIUrl('User/ChangePassword');
  

  constructor(injector: Injector) {
    super(injector);
  }
  
  public ResetPassword(payload: any): Observable<any> {
    return this.http.post<any>(this.RESET_PASSWORD_AND_SEND_EMAIL, payload);
  }

  public ChangePassword(payload: any): Observable<any> {
    return this.http.post<any>(this.CHANGE_PASSWORD, payload);
  }

 
}
