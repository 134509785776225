import { Injectable } from '@angular/core';
import { KeyValuePair } from '../model/key-value-pair.model';
import { TrackerSections } from '../model/tracker.models';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {
  public clearAllFilter$ = new Subject<any>();
  public singleSelectedItem$ = new Subject<number>();
  public newSubcategoryList$ = new Subject<number[]>();
  public selectedTile$ = new BehaviorSubject<KeyValuePair>({id:0,name:''});
  public sideBarToggle$ = new BehaviorSubject<boolean>(true);
  public selectedTracker$ = new BehaviorSubject<TrackerSections>({id:0,templateId:'',name:'',children:[]});
  constructor() { }

}
