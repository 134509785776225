import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService } from './base.service';
import { DEFAULT_EXCEL_NAME, DEFAULT_PDF_NAME, DEFAULT_PDF_TITLE, EXCEL_FILE_EXTENTION, PDF_EXTENSION } from '../model/constants';
import { switchMap } from 'rxjs/operators';
import { DownloadFileUrls } from '../model/download.models';
import { DownloadFileComponent } from 'src/app/shared/common/download-file/download-file.component';
import FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})

export class DownloadService extends BaseService {
    private readonly GET_AEOI_FILE_URL = this.generateAPIUrl('CRSFatca/GetAEOIFile');
    private readonly GET_CRS_FATCA_FILE_URL = this.generateAPIUrl('CRSFatca/GetCRSFatcaFile');
    private readonly GET_NEWS_ATTACHMENT_FILE_URL = this.generateAPIUrl('NewsAndAlerts/GetNewsAttachmentFile');
    private readonly GET_REPORTING_DASHBOARD_FILE_URL = this.generateAPIUrl('ReportingDashboard/GetReportingDashboardFile');
    private readonly GET_SOURCE_DOCUMENTATION_FILE_URL = this.generateAPIUrl('Dashboard/GetSourceDocumentationFile');
    private readonly GET_SAVED_VIEW_REPORT_EXCEL_URL = this.generateAPIUrl('CRSFatca/GetSavedViewExcelExportNew');

     
    constructor(injector: Injector
              , private domSanitizer: DomSanitizer) {
          super(injector);
    }

    public getAEOIAttatchmentBinary(aeoiId: any): Observable<any> {
      return this.loadFile(this.GET_AEOI_FILE_URL, {aeoiId},DEFAULT_PDF_NAME);
    }

    public getCrsFatcaAttachment(payload: any): Observable<any> {
      return this.loadFile(this.GET_CRS_FATCA_FILE_URL, payload,DEFAULT_PDF_NAME);   
    }

    public getNewsAttachmentFile(payload: any): Observable<any> {
      return this.loadFile(this.GET_NEWS_ATTACHMENT_FILE_URL, payload,DEFAULT_PDF_NAME);
    }

    public GetReportingDashboardFile(attachmentId: number): Observable<any> {
      return this.loadFile(this.GET_REPORTING_DASHBOARD_FILE_URL,  {attachmentId},DEFAULT_PDF_NAME);
    }

    public GetSourceDocumentationFile(sourceId: number): Observable<any> {
      return this.loadFile(this.GET_SOURCE_DOCUMENTATION_FILE_URL, {sourceId},DEFAULT_PDF_NAME);
    }
    
    public GetSavedViewExcelFile(sourceId: any): Observable<any> {
      return this.loadFile(this.GET_SAVED_VIEW_REPORT_EXCEL_URL, sourceId,DEFAULT_EXCEL_NAME);
    }

    private loadFile(fileURL: string, payload: any, docType: string): Observable<any> {
      return this.http.post<any>(fileURL, payload, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe( switchMap(resp => {
        const disposition = resp.headers.get('content-disposition');
        let fileName = docType;
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = regex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        if(docType.includes(EXCEL_FILE_EXTENTION))
        { 
           FileSaver.saveAs(resp.body, fileName);
           return of(1); 
        }
        let url = window.URL.createObjectURL(resp.body);
        let safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0');
        const downloadfileUrls : DownloadFileUrls ={ safeUrl : safeUrl, url : url }
        return  of(downloadfileUrls);
      }))
    }

    private onpenFileInNewWindow(resp){
      const fileURL = URL.createObjectURL(resp.body);
      window.open(fileURL,'_blank' ,'popup=1 width=800,height=450,top=150,left=200');
      window.focus()
    }

    public loadFileDownload(viewRef : ViewContainerRef,downloadfileUrls : DownloadFileUrls, fileName : string){
      viewRef.clear();
      let fileTitle = fileName.split(PDF_EXTENSION)[0];
      let ref = viewRef.createComponent(DownloadFileComponent);
      let obj = (ref.instance as DownloadFileComponent);
      obj.fileName = fileName;
      obj.fileLink = downloadfileUrls.url
      obj.fileTitle = fileTitle
      obj.fileSafeUrl = downloadfileUrls.safeUrl;  
  }
  
  
}