import { Component} from '@angular/core';
import { DeadLineForSlider, TileCode } from '../../../core/model/dashboard.model';
import { NewsAlert, NewsAlertForSlider } from '../../../core/model/news-alert.model';
import { KeyValuePair, KeyValueSelection } from '../../../core/model/key-value-pair.model';
import { CONTACT_US, INVALID_USER_ACCESS_PAGE, SLIDER_ITEMS_TO_ADD } from '../../../core/model/constants';
import { DashboardService } from '../../../core/services/dashboard.service';
import { Router } from '@angular/router';
import { NewsAndAlertsService } from '../../../core/services/news-alert.service';
import { CommonDataService } from '../../../core/services/common-data.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { NewsType } from '../../../core/model/enum.model';

@Component({
  selector: 'app-news-alert-siderbar',
  templateUrl: './news-alert-siderbar.component.html',
  styleUrls: ['./news-alert-siderbar.component.scss']
})
export class NewsAlertSiderbarComponent {

  public popTitle: string = 'News Details'
  public dashboardTiles: KeyValueSelection[] = [];
  public upcomingDeadLinesForSlider: DeadLineForSlider[] = [];
  public newsAlertsForSlider: NewsAlertForSlider[] = [];
  public newsAlertsOnBanner: NewsAlertForSlider[] = [];
  public newsAndAlertAttachmentList: KeyValuePair[] = [];
  public contactUs: string = CONTACT_US;
  public contactString: string = `{mailto: ${this.contactUs}}`
  public get TileCode(): typeof TileCode { return TileCode; }
  public newsAlterIndex: number = 0;
  public endIndex: number = 0
  public deadlinesOnBanner: DeadLineForSlider[] = [];
  public deadLineIndex: number = 0;
  public endDeadLineIndex: number = 0
  public isNewsFocused: boolean = false
  public isNewsScrolled: boolean = false
  public isDeadlineScrolled: boolean = false
  public isDeadlinesFocused: boolean = false
  public displayStyle: boolean = false
  public popupTitle: string | false = ''
  public clickedNewsButton : boolean = false;
  public clickedDeadlineButton : boolean = false;

  public popedUpNewsAlert: NewsAlert
  public get NewsType(): typeof NewsType { return NewsType; }


  constructor(private router: Router
    , private dashboardService: DashboardService
    , private newsAndAlertsService: NewsAndAlertsService
    , private navigationService: NavigationService
    , private commonDataService: CommonDataService) {
    this.getUpcomingDeadlineDetail();
    this.getNewsAndAlertDetail();
  }

  ngOnInit() {
    //NewsAlertSequance
    this.newsAlertSequance();
    this.deadLineSequance();
    
    this.newsAlertSequance(); 
    this.deadLineSequance();
    setInterval(() => { this.newsAlertSequance(); this.deadLineSequance(); }, 5000)
  }

  private getUpcomingDeadlineDetail(): void {
    this.dashboardService.getUpcomingDeadlineDetail().subscribe(resp => {
      this.upcomingDeadLinesForSlider = resp; 
    },
      (error) => {
        if (error.status === 422) {
          this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
        }
      });
  }


  private getNewsAndAlertDetail(): void {
    this.newsAndAlertsService.GetNewsAndAlertsSlider().subscribe(resp => {
      this.newsAlertsForSlider = resp.Datatable;      
    },
      (error) => {
        if (error.status === 422) {
          this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
        }
      });
  }

  private getNewsAndAlertAttachmentList(newsAlert: NewsAlert): void {
    const payload = {
      newsId: newsAlert.id,
      newsType: newsAlert.newsType
    };
    this.newsAndAlertsService.GetNewsAndAlertsAttachmentList(payload).subscribe(resp => {
      this.newsAndAlertAttachmentList = resp;
      this.showPopup();
    },
      (error) => {
        if (error.status === 422) {
          this.navigationService.navigateToUrl(INVALID_USER_ACCESS_PAGE);
        }
      });
  }

  public openNewsPopup(newsAlert: NewsAlert) {
    this.popedUpNewsAlert = newsAlert
    this.getNewsAndAlertAttachmentList(newsAlert)
  }

  public handleClick(tile: KeyValuePair) {
    this.commonDataService.selectedTile$.next(tile);
  }

  public closePopup(val: any) {
    this.displayStyle = false;
  }

  public showPopup() {
    this.popupTitle = 'News & Alerts'
    this.displayStyle = true
  }

  public navigateToNewsAndAlert() {
    this.router.navigate(['./newsalert'])
  }


  public newsAlertSequance(isbuttonClicked : boolean = false): void {
    let totalNewsCount = this.newsAlertsForSlider.length;
    if (!this.isNewsFocused && !this.isNewsScrolled && totalNewsCount > 0) {
      if (this.newsAlterIndex > totalNewsCount)
        this.newsAlterIndex = 0;

      this.endIndex = this.newsAlterIndex + 3;
      if (this.endIndex > totalNewsCount)
        this.endIndex = totalNewsCount;
      
      this.newsAlertsOnBanner = this.newsAlertsForSlider.slice(this.newsAlterIndex, this.endIndex);
      this.newsAlterIndex = this.newsAlterIndex + 3
      if (this.newsAlterIndex > totalNewsCount)
        this.newsAlterIndex = 0;
      if(isbuttonClicked)
        this.markNewsButtonClicked();
    }
  }

  public newsAlertSequanceReverse(isbuttonClicked : boolean = false): void {
    let totalNewsCount = this.newsAlertsForSlider.length;
    if (!this.isNewsFocused && !this.isNewsScrolled && totalNewsCount > 0) {
      if (this.newsAlterIndex < 0)
        this.newsAlterIndex = totalNewsCount;
      this.endIndex = this.newsAlterIndex - 3;
      if (this.endIndex < 0)
        this.endIndex = 0;

      this.newsAlertsOnBanner = this.newsAlertsForSlider.slice(this.endIndex,this.newsAlterIndex);
      this.newsAlterIndex = this.newsAlterIndex - 3
      if (this.newsAlterIndex < 0)
        this.newsAlterIndex = totalNewsCount;

      if(isbuttonClicked)
        this.markNewsButtonClicked();
    }
  }

  private markNewsButtonClicked(){
    this.clickedNewsButton = true;
    let x = setInterval(()=>{
      if(!this.isNewsFocused)
      { this.clickedNewsButton = false;
        clearInterval(x);
      }
   },10000)
  }

  public setFocusOnNews(val: any) {
    this.isNewsFocused = val
    if (this.isNewsFocused && !this.isNewsScrolled && !this.clickedNewsButton)
          this.newsAlertsOnBanner =  this.newsAlertsForSlider.slice(0, 10);
  }

  public loadMoreNews(){
    if(!this.clickedNewsButton){
      this.markNewsScrolled();
      const next = this.newsAlertsOnBanner.length;
      this.newsAlertsOnBanner = [...this.newsAlertsOnBanner, ...this.newsAlertsForSlider.slice(next, next + SLIDER_ITEMS_TO_ADD)];
    }
  }

  private markNewsScrolled(){
    if(!this.isNewsScrolled && !this.clickedNewsButton){
        this.isNewsScrolled = true;
        let x = setInterval(()=>{
          if(!this.isNewsFocused)
          { this.isNewsScrolled = false;
            clearInterval(x);
          }
       },10000)
    }
  }


  public deadLineSequance(isbuttonClicked : boolean = false): void {
    let totalDeadLines = this.upcomingDeadLinesForSlider.length;
    if (!this.isDeadlinesFocused && !this.isDeadlineScrolled && totalDeadLines > 0) {
      if (this.deadLineIndex > totalDeadLines)
        this.deadLineIndex = 0;

      this.endDeadLineIndex = this.deadLineIndex + 3;
      if (this.endDeadLineIndex > totalDeadLines)
        this.endDeadLineIndex = totalDeadLines;

      this.deadlinesOnBanner = this.upcomingDeadLinesForSlider.slice(this.deadLineIndex, this.endDeadLineIndex);
      this.deadLineIndex = this.deadLineIndex + 3
      if (this.deadLineIndex > totalDeadLines)
        this.deadLineIndex = 0;

      if(isbuttonClicked)
        this.markDeadlineButtonClicked();
    }
  }

  public deadLineSequanceReverse(isbuttonClicked : boolean = false): void {
    let totalDeadLines = this.upcomingDeadLinesForSlider.length;
    if (!this.isDeadlinesFocused && !this.isDeadlineScrolled && totalDeadLines > 0) {
      if (this.deadLineIndex < 0)
        this.deadLineIndex = totalDeadLines;
      
      this.endDeadLineIndex = this.deadLineIndex - 3;
      if (this.endDeadLineIndex < 0)
        this.endDeadLineIndex = 0;

      this.deadlinesOnBanner = this.upcomingDeadLinesForSlider.slice(this.endDeadLineIndex, this.deadLineIndex);
      this.deadLineIndex = this.deadLineIndex - 3
      if (this.deadLineIndex < 0)
        this.deadLineIndex = totalDeadLines;
      
      if(isbuttonClicked)
        this.markDeadlineButtonClicked();
    }
  }

  private markDeadlineButtonClicked(){
    this.clickedDeadlineButton = true;
    let x = setInterval(()=>{
      if(!this.isDeadlinesFocused)
      { this.clickedDeadlineButton = false;
        clearInterval(x);
      }
    },10000)
  }

  public setFocusOnDeadlines(val: any) {
    this.isDeadlinesFocused = val
    if (this.isDeadlinesFocused && !this.isDeadlineScrolled && !this.clickedDeadlineButton)
      this.deadlinesOnBanner =  this.upcomingDeadLinesForSlider.slice(0, 10);
  }

  public loadMoreDeadline(){
    if(!this.clickedDeadlineButton){
      this.markDeadLineScrolled();
    const next = this.deadlinesOnBanner.length;
    this.deadlinesOnBanner = [...this.deadlinesOnBanner, ...this.upcomingDeadLinesForSlider.slice(next, next + SLIDER_ITEMS_TO_ADD)];
  }
  }
  private markDeadLineScrolled(){
    if(!this.isDeadlineScrolled && !this.clickedDeadlineButton){
        this.isDeadlineScrolled = true;
        let x = setInterval(()=>{
          if(!this.isDeadlinesFocused)
          { this.isDeadlineScrolled = false;
            clearInterval(x);
          }
        },10000)
    }
  }
 
}
