<div class="p-4">
    <p><strong>This Privacy Statement applies to Deloitte Tax LLP (also referred to as “Deloitte Tax”, “we”, “us”, and
            “our”), an entity within the Deloitte Network.</strong> As used in this Privacy Statement, the “Deloitte Network”
        refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by guarantee (“DTTL”),
        its network of member firms and their related entities. DTTL and each of its member firms are legally separate
        and independent entities. Please see deloitte.com/about for a detailed description of the legal structure of
        Deloitte Touche Tohmatsu Limited and its member firms.</p>
    <p>This Privacy Statement sets out how we will process your personal information as part of our provision of tax,
        social security and (in certain jurisdictions outside of the United States) immigration related services as may
        be relevant to you. Deloitte Tax is providing these services either under a direct contract with you or via a
        contract with another person (such as a company or a partnership or a trustee) who has asked us to provide the
        services.</p>
    <p>Your personal information will be protected and handled with consideration for its confidentiality and Deloitte
        Tax will only disclose it as set out in the “To whom will we disclose your personal information?” section below.
    </p>
    <p>In this Privacy Statement, we refer to handling, collecting, protecting and storing your personal information as
        "processing".</p>
    <h6>
        What personal information do we collect?
    </h6>
    <p>Deloitte Tax may collect personal information relating to you such as:</p>
    <ul class="px-5 pb-3">
        <li>name</li>
        <li>contact details (such as work or home address, email and phone numbers)</li>
        <li>date of birth</li>
        <li>government identifiers (such as social security number and passport details)</li>
        <li>financial information</li>
        <li>calendar data (where applicable)</li>
    </ul>
    <p>In order to provide services to you, Deloitte Tax may receive and also need to process personal information about
        you that may be considered special category (or “sensitive”) personal information (special category personal
        information is considered to include information about your health, racial or ethnic origin, political opinions,
        religious or philosophical beliefs, trade union membership, genetic data, biometric data or sexual orientation).
        Special category personal information about you includes information that may be reasonably inferred from other
        information that we receive.</p>
    <p>Where we receive special category personal information or other information from which special category personal
        information can be reasonably inferred, we will require explicit consent in order to process it.</p>

        <h6>
                How do we collect personal information?
            </h6>
    <p>Deloitte Tax may collect personal information about you in different ways:</p>
    <ul class="px-5 pb-3">
        <li>you may provide it directly to us</li>
        <li>we may obtain it because of the services that Deloitte Tax provides or has previously provided</li>
        <li>we may receive it from other members of the Deloitte Network or from third parties, such as your
            employer/partnership, or a tax authority and/or other relevant authority/administrative bodies</li>
        <li>we may observe or infer it from the information you provide to us and/or the way you interact with us</li>
    </ul>
    <p>This personal information can be received in any manner, including in-person discussions, telephone
        conversations, and electronic or other written communications.</p>
    <p>Without access to all the personal information that we need, we may be unable to provide or complete the
        services. </p>
    <p>Where another party (such as a company or a partnership or any third parties acting on your or their behalf)
        provides your personal information to us, they must also comply with their obligations under the relevant
        privacy laws and regulations. If you believe that the entity for whom you work or a third party has not provided
        you with details of the personal information that it holds about you and/or has not obtained your authority to
        provide us with that personal information for processing as described in this Privacy Statement, then please
        contact such entity directly.</p>
        <h6>Disclosing personal information to us relating to third parties</h6>
    <p>If any personal information which you provide to us relates to any third party, for example a spouse or civil
        partner, individuals (including children) who depend on you financially, or a joint account holder or a
        beneficiary or trustee of a trust, then by providing us with their personal information you will need to ensure
        that you have obtained any necessary permissions from those persons to the use of their personal information in
        the way set out in this Privacy Statement, or you are otherwise permitted to give us this personal information.
        You should share a copy of this Privacy Statement with those other individuals before disclosing any personal
        information about them to us.</p>

        <h6>How do we use your personal information?</h6>
    <p>Deloitte Tax processes personal information about you to:</p>
    <ul class="px-5 pb-3">
        <li>establish or maintain our relationship with you</li>
        <li>provide services to you and/or family member(s) or to the entity that has engaged us to provide the services
        </li>
    </ul>
    <p>We may also use your personal information for the purposes of, or in connection with:</p>
    <ul class="px-5 pb-3">
        <li>compliance with applicable legal, regulatory or professional requirements</li>
        <li>protecting our rights and/or property</li>
    </ul>

    <h6>On what basis do we process personal information about you?</h6>
    <p>This Privacy Statement sets out the grounds upon which we rely in order to process your personal information.</p>
    <p>We may use your personal information for the purposes outlined above because:</p>
    <p>(a) where relevant, we have a contract with you to provide services and processing your personal information is
        necessary for the performance of such contract;</p>
    <p>or (b) we have a legitimate interest in processing your personal information, which may be to:</p>
    <ul class="px-5 pb-3">
        <li>provide services to you and/or to the entity that has engaged us to provide the services;</li>
        <li>support the management of our client engagements;</li>
        <li>evaluate, develop or improve our services or products; or</li>
        <li>protect our business interests.</li>
    </ul>
    <p>or (c) we are subject to legal, regulatory or professional obligations.</p>

    <h6>To whom will we disclose your personal information?</h6>
    <p>In connection with one or more of the purposes outlined in this Privacy Statement, we may disclose your personal
        information to:</p>
    <ul class="px-5 pb-3">
        <li>other members of the Deloitte Network</li>
        <li>those with whom you have requested us to share information, such as your spouse or civil partner</li>
        <li>competent authorities, including courts and authorities regulating us or another member of the Deloitte
            Network, in each case to comply with legal, regulatory or professional obligations or requests</li>
        <li>vendors and administrative, support, infrastructure and other service providers handling your information on
            our behalf; in each case, such vendors and service providers will be contractually bound by confidentiality
            and privacy obligations consistent with the obligations in this Privacy Statement</li>
        <li>third parties to whom we disclose information in the course of providing services to you or to the entity
            that has engaged us to provide the services</li>
    </ul>
    <p>Deloitte Tax does not sell or lease your personal information to others.</p>
    <p>Please note that some of the recipients of your personal information referred to above may be based in countries
        or regions without data protection rules similar to those in effect in your area of residence. In such cases,
        adequate safeguards will be in place to protect your personal information. </p>
    <p>For further details about the transfers described above and the adequate safeguards used by Deloitte Tax with
        respect to such transfers, please contact us using the details below.</p>

        <h6>How do we keep your personal information secure?</h6>
    <p>We have in place reasonable commercial standards of technology and operational security to protect your personal
        information from loss, misuse and unauthorised access, disclosure, alteration or destruction. Only authorised
        personnel, with appropriate awareness of privacy obligations, are provided access to your personal information.
    </p>

    <h6>How long will we keep your information?</h6>
    <p>We retain personal information as long as is necessary to fulfil the purposes identified in this Privacy
        Statement or (i) as otherwise necessary to comply with applicable laws or professional standards, or (ii) as
        long as the period in which litigation or investigations might arise in respect of our services.</p>

        <h6>What are your rights in relation to your personal information?</h6>
    <p>You have various rights in relation to your personal information. In particular, you have a right to:</p>
    <ul class="px-5 pb-3">
        <li>obtain confirmation that we are processing your personal information and request a copy of the personal
            information we hold about you</li>
        <li>ask that we update the personal information we hold about you, or correct such information that you think is
            inaccurate or incomplete</li>
    </ul>
    <p>Depending on the jurisdiction in which you are located, you may also have the right to:</p>
    <ul class="px-5 pb-3">
        <li>ask that we delete personal information that we hold about you, or restrict the way in which we use your
            personal information</li>
        <li>withdraw consent to our processing of your personal information (to the extent our processing is based on
            your consent)</li>
        <li>ask us to stop or start sending you marketing messages at any time</li>
        <li>obtain and/or move your personal information to another service provider</li>
        <li>object to our processing of your personal information</li>
    </ul>
    <p>Where our processing of special category personal information is reliant on your consent and you withdraw that
        consent, we will cease processing the relevant information for the purposes of providing our services and the
        effect may be that we are no longer able to provide the services.</p>
    <p>However, we may still retain a copy of the relevant information for as long as necessary to comply with
        applicable laws or professional standards, or as long as the period in which litigation or investigations might
        arise in respect of our services.</p>
    <p>To exercise any of your rights or raise any questions that you have about our use of your personal information,
        please contact us using the details below.</p>

        <h6>Privacy Shield Notice</h6>
    <p>Deloitte LLP and its United States affiliates, including Deloitte Tax, adhere to the EU-U.S. and Swiss Privacy
        Shield Framework as set forth by the U.S. Department of Commerce with respect to personally identifiable
        information that is transferred from the European Economic Area and Switzerland to the United States within the
        scope of their Privacy Shield certifications and therefore provides for an adequate level of data protection
        under European Union General Data Protection Regulation and as recognized by the European Commission. To learn
        more, see our <a
            href="https://www2.deloitte.com/us/en/footerlinks1/privacy-shield.html?icid=bottom_privacy-shield">Privacy
            Shield Notice</a>. You may have the right to lodge a complaint with your local European or Swiss data
        protection authority regarding our processing of such personal information.</p>

        <h6>Changes to this Privacy Statement</h6>
    <p>We may modify or amend this Privacy Statement from time to time at our discretion. When we make changes to this
        Privacy Statement, we will amend the revision date at the top of this page and the modified or amended Privacy
        Statement shall apply to you and your personal information as of that revision date. We encourage you to review
        the Privacy Statement on our website periodically to be informed about how we are protecting your personal
        information.</p>

        <h6>Contact Us</h6>
    <p>If you have any questions or concerns regarding this Privacy Statement or your personal information, please
        contact our data protection officer by email at <a
            href="mailto:USPrivacyQuestions@Deloitte.com">USPrivacyQuestions@Deloitte.com</a>. Our European Union
        representative can be contacted by email at <a
            href="mailto:EURepresentative@Deloitte.com">EURepresentative@Deloitte.com</a>.
    </p>
</div>