<app-page-content>
    <div class="dashboard ">
            <div class="tiles">
                <div class="" style="display: flex; flex-wrap: wrap;">
                    <button class="dashTiles" *ngFor="let tile of dashboardTiles" (click)="handleClick(tile)"
                        [routerLink]="tile.routerLink">
                        <span class="tilesTitle"> {{tile.name}} </span>
                    </button>
                </div>
            </div>
        <div>
             <app-news-alert-siderbar></app-news-alert-siderbar>
        </div>
    </div>
</app-page-content>