import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from 'src/app/core/services/notify.service';
import { LOGIN_FAILURE_TEXT, LOGIN_TEXT, PASSWORD_CHANGE_FAILURE, PASSWORD_CHANGE_SUCCESS, PASSWORD_CHANGE_TITLE, PASSWORD_CONFIRMATION_CHECK, PASSWORD_PLACEHOLDER, PASSWORD_RESET_FAILURE, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_TITLE, PRIVACY } from 'src/app/core/model/constants';
import { FormLoginService } from 'src/app/core/services/form-login.service';
import { HttpStatusCode } from '@angular/common/http';


@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public isLogging = true;
  public formLoginEnabled : boolean = false;
  public privacy : string= PRIVACY;
  public userId  : string = '' 
  public dispPopup : boolean = false
  public changePassPopup : boolean = false
  public passwordPlaceholder : string = PASSWORD_PLACEHOLDER

  public title :string =PASSWORD_CHANGE_TITLE;
  public resettitle :string =PASSWORD_RESET_TITLE;
  public fn : string =''

  public lgForm : FormGroup
  public rsForm : FormGroup
  public cPForm : FormGroup

  constructor( private authService: AuthService
              ,private router: Router
              ,private fb : FormBuilder
              ,private loginService : FormLoginService
              ,private notifyService : NotifyService) {
            }

  ngOnInit() {
    this.isLogging = true;
    if(!this.authService.isFormLoginEnabled(window.location.href))
      this.authService.login(window.location.href)
        .pipe(
          finalize(() => this.isLogging = false),
          untilDestroyed(this)
        ).subscribe(() => {
          this.router.navigateByUrl('/');
        });
     else
     {
      this.formLoginEnabled = true
      this.initializeForm()
     }
  }

  public formLogin(){
      this.userId = this.lgForm.controls.loginId?.value;
      let password = this.lgForm.controls.pwd?.value
      let payload = { userId : this.userId,password: password  }
      this.notifyService.info(LOGIN_TEXT)
      this.authService.formLogin(payload)
      .subscribe(resp=>{
        this.isLogging = false
        if(resp?.guid != null || resp?.guid != undefined || resp?.guid !='')
          {
          if(!resp?.isNewAccount) 
              this.router.navigateByUrl('/') 
          else
           {
            this.fn = resp.firstname
            this.changePassPopup  = true
           } 
        }
        else
           this.notifyService.error(LOGIN_FAILURE_TEXT)
      },err=> { 
        this.initializeForm();
        this.notifyService.error(LOGIN_FAILURE_TEXT)
      });
  }

  private initializeForm(){
    this.lgForm =  this.fb.group({
      loginId : ['',[Validators.required]],
      pwd : ['',[Validators.required]],
      tremsAndCondition : [false,[Validators.requiredTrue]],
    })

    this.rsForm  =  this.fb.group({
      eml : ['',[Validators.email , Validators.required]]
    })

    this.cPForm  =  this.fb.group({
       oldPass : ['',[Validators.required]]
      ,newPass : ['',[Validators.required]]
      ,confirmNewPass : ['',[Validators.required]]
      ,tremsAndCondition : [false,[Validators.requiredTrue]],
    })
  }

  public markFormAsTouched(){
    this.lgForm.markAllAsTouched();
  }

  public rsFormAsTouched(){
    this.rsForm.markAllAsTouched();
  }

  public cPFormAsTouched(){
    this.cPForm.markAllAsTouched();
  }

  public showPopup(val : any){
    this.dispPopup = true;
  }

  public closePopup(val : any){
    this.dispPopup = false;
  }

  public showChangePassPopup(val : any){
    this.changePassPopup = true;
  }
  public closeChangePassPopup(val : any){
    this.changePassPopup = false;
    this.initializeForm();
  }


  public resetPassword(){ 
    this.dispPopup = false;
    this.loginService.ResetPassword({userEmail : this.rsForm.controls.eml?.value})
    .subscribe(x=>{
      if(x?.errorCode == HttpStatusCode.Ok)
         this.notifyService.success(x.result);
       else 
         this.notifyService.error(x.result);
    },err=> this.notifyService.error(PASSWORD_RESET_FAILURE))
  }

  public changePassword(){
    let newPassword = this.cPForm.value.newPass;
    let confirmNewPass = this.cPForm.value.confirmNewPass;
    let oldPassword = this.cPForm.value.oldPass;
    let payload = {newPassword : newPassword ,oldPassword : oldPassword ,userId : this.userId}
    if(newPassword != confirmNewPass)
       { this.notifyService.warning(PASSWORD_CONFIRMATION_CHECK)
       return;
    }

    this.loginService.ChangePassword(payload).subscribe(x=>{
      if(x?.errorCode == HttpStatusCode.Ok)
       { this.notifyService.success(PASSWORD_CHANGE_SUCCESS);
        this.changePassPopup= false;
        }
      else 
        this.notifyService.error(x?.result);
      },err=> this.notifyService.error(PASSWORD_CHANGE_FAILURE))    
  }
  ngOnDestroy(): void { }

}
