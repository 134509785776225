<div> 

   <kendo-grid #KGrid class="kendo-grid" 
        [filterable]="'menu-row'" 
        [(filter)] = 'filter'
        (filterChange)="filterChange($event)"
        [data]="finalGridData"
        [rowHeight]="36"
        [height]="gridHeight"
        [skip]="gridState.skip"
        [pageSize]="gridState.take"
        scrollable="virtual"
        (pageChange)="pageChange($event)"
        [navigable]="true"
        >

        <kendo-grid-column  #girGridcolumn
            [headerStyle]="{ 'background-color':'#000' ,'color' :'#fff' }"
            [headerClass]=" item.filterCode != 'MSF' ?  'column-without-filter'   : 'column-with-filter'"
            [style]="{'font-size' : '12px','text-align': 'left'   }"
            *ngFor="let item of elementsMeta!;let indexOfelement=index;" field="{{item.columnField}}"
            title="{{item.columnTitle}}" filterCode="{{item.filterCode}}"
            [width]=" columnDescriptor.isExpanded ?  (columnDescriptor.columnName  == item.columnTitle ? columnDescriptor.columnSize :  columnDescriptor.otherColumnSize) : 200 "
            >

            <ng-template  *ngIf="item.filterCode != 'SSF'"  kendoGridFilterCellTemplate [hidden]="true" let-filter let-column="column">
                <kendo-grid-string-filter-cell 
                    [showOperators]="false" 
                    [column]="column"  
                    [filter]="filter">                          
                </kendo-grid-string-filter-cell>
              </ng-template>
              <ng-template *ngIf="item.filterCode == 'SSF'" kendoGridFilterCellTemplate   let-filter let-column="column">
                <dropdown-filter
                    [filter]="filter"
                    [data]="distinctPrimitive(column.field)"
                    [textField]="column.field"
                    [valueField]="column.field">
                </dropdown-filter>
            </ng-template>
            <ng-template *ngIf="item.filterCode === 'MSF'" kendoGridFilterMenuTemplate let-column="column"
                let-filter="filter" let-filterService="filterService">
              <multicheck-filter 
                  [isPrimitive]="true" 
                  [field]="column.field" 
                  [filterService]="filterService"
                  [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
            </ng-template>   

            <ng-template kendoGridCellTemplate let-dataItem>
                <button class="localCellEvent" (click)="handleCellClick(indexOfelement,item.columnTitle,
                        dataItem['DataElementName'],dataItem[item.columnField]
                        ,dataItem['Id'],dataItem['IDAEOIReporting']
                        ,dataItem['id'],dataItem['categoryName']
                        ,dataItem['isCountrySpecific'],dataItem['dataElementName']
                        ,dataItem['JurisdictionName'],dataItem['id'],dataItem['newsType']
                        ,dataItem['jurisdictionName'])">
                <div class="content_wrapper"
                    [markjsHighlight]="value | async"
                    [markjsConfig]="{separateWordSearch: true}"
                    [scrollToFirstMarked]="false"> 

                    <ng-container *ngIf="haveDocumentsLinks">
                        <ng-container *ngIf="dataItem[item.columnField] | processCellData; else noPdfDocument ; let record">
                            <div class="cellFormat" title="{{dataItem[item.columnField]}}"
                                [innerHTML]="record?.displayValue"></div>
                            <ng-container *ngIf="record?.type == pdfExtention ">
                                <span><em class=" ms-3 far fa-file-pdf fa-1x"></em></span>
                            </ng-container>
                            <ng-container *ngIf="record?.type == webLinkExtention">
                                <a [href]="record?.linkString" target="_blank"><em class="fas fa-link ms-3 fa-1x"></em></a> 
                                </ng-container>
                        </ng-container>
                        <ng-template #noPdfDocument> 
                            <ng-container *ngIf="item.columnTitle| displayDetailsIcon">
                                <span>Details</span></ng-container>
                        </ng-template>
                </ng-container>

                <ng-container *ngIf="!haveDocumentsLinks">
                    <div class="cellFormat" title="{{dataItem[item.columnField]}}"
                            [innerHTML]="dataItem[item.columnField]"></div>
                    <ng-container *ngIf="item.columnTitle| displayDetailsIcon">
                        <span>Details</span>
                    </ng-container>
                </ng-container>
                
            </div> 
            </button>
         </ng-template>
        </kendo-grid-column>
        
        <kendo-grid-excel [fileName]="gridExportFileName + '.xlsx'"></kendo-grid-excel>
        <kendo-grid-pdf [autoPrint]="autoPrint" 
                        [allPages]="false"
                        paperSize="A2" 
                        [repeatHeaders]="true"
                        [fileName]="gridExportFileName + '.pdf'">
                <kendo-grid-pdf-margin top="3cm" left="0.1cm" right="0.1cm"  bottom="3cm" >                        
                </kendo-grid-pdf-margin>
                    <ng-template kendoGridPDFTemplate>
                        <div class="pdf-header"> {{pdfTitle}} 
                          <div class="pdf-sub-header">{{copyRight}}</div>
                        </div>
                    </ng-template>
        </kendo-grid-pdf>    
        

         <ng-template kendoGridToolbarTemplate position="bottom"> 
          <kendo-textbox class="search-text" [placeholder]=searchInAllColumn [(ngModel)]="columnFilterValue"
            (valueChange)="allColumnFilterMethod($event)" [style.width.px]="300"></kendo-textbox>
            <kendo-datapager
                [pageSize]="pagerState.take"
                [skip]="pagerState.skip"
                [total]="totalSize"
                [type]="'numeric'"
                (pageChange)="onPagerChange($event, KGrid)">
                <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <div class="k-pager-numbers-wrap">
                  <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
                    <kendo-datapager-numeric-buttons [buttonCount]="buttonCount">
                    </kendo-datapager-numeric-buttons>
                  <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
                </div>
                <kendo-datapager-info></kendo-datapager-info>
                <kendo-datapager-page-sizes [pageSizes]="[10,20,totalSize]"> </kendo-datapager-page-sizes>
              </ng-template>
          </kendo-datapager>
          </ng-template>  

</kendo-grid>  

<ng-template #printFile (closedPrintGrid)="clearReference($event)"></ng-template>
</div>