<nav id="header" class="navbar navbar-expand-lg navbar-dark fixed-top">
  <a class="navbar-brand" routerLink="{{ homePageUrl }}">
    <img src="./assets/Deloitte.svg" class="brand-logo" id="brandLogo" />
    <span class="appName">GIR Tracker</span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div *ngIf="authUser$ | async as user" class="collapse navbar-collapse ipsc-top-nav" id="navbarSupportedContent">

    <ul class="top-profile-nav">
      <li>
        <span>Welcome, {{user.firstName}} {{user.lastName}}</span>
      </li>
      <li (click)="logout()" class='logout-btn d-flex align-items-center'>
        <span class="me-2">Sign out </span>
        <em class="fad fa-sign-out-alt ms-2" aria-hidden="true"></em>
      </li>
    </ul>
  </div>
</nav>