import { Component, ViewChild } from '@angular/core';
import { DEFAULT_JURISDICTION_OBJ, PDF_HEADER_INFO_EXCHANGE } from 'src/app/core/model/constants';
import { GIRGridCellEvent, GirdColumn } from 'src/app/core/model/data-grid.model';
import { Jurisdiction } from 'src/app/core/model/tracker.models';
import { InformationExchangeService } from 'src/app/core/services/information-exchange.service';
import { DataGridComponent } from 'src/app/shared/common/data-grid/data-grid.component';

@Component({
  selector: 'app-information-exchange',
  templateUrl: './information-exchange.component.html',
  styleUrls: ['./information-exchange.component.scss']
})
export class InformationExchangeComponent {

  public title: string = 'Information Exchange'
  public showLastupdated: boolean = false;
  public displayPopup: boolean = false
  public popupSpecificTitle: string | undefined
  public cellDescription: string | undefined
  public jurisdictions: Jurisdiction[] = []
  public defaultJurisdiction: Jurisdiction = DEFAULT_JURISDICTION_OBJ
  public selectedJurisdiction: Jurisdiction = new Jurisdiction;
  public elements: Object[] = []
  public columnName: GirdColumn[] = []
  public elementsMeta: GirdColumn[] = []
  public docTitle : string = '';
  @ViewChild('infoExchangeGrid') infoExchangeGrid: DataGridComponent;

  constructor(private informationExchangeService: InformationExchangeService) { }

  ngOnInit() {
    this.informationExchangeService.getInformationJurisdictions().subscribe(resp => {
      this.jurisdictions = resp;
      if(this.jurisdictions?.length > 0)
       {  this.selectedJurisdiction = this.jurisdictions[0];
          this.handleJurisdictionchange(this.selectedJurisdiction)
       }
    })
    this.getDataByCountry();
  }

  public handleJurisdictionchange(val: any) {
    this.docTitle = PDF_HEADER_INFO_EXCHANGE
    this.docTitle = this.docTitle.replace('{Jurisdiction}',this.selectedJurisdiction?.jurisdictionName)
                                 .replace('{PrintDate}',(new Date()).toDateString());
    this.getDataByCountry();
  }

  public getDataByCountry() {
    this.informationExchangeService
      .getInformationByJurisdiction({ fromJurisdictionId: this.selectedJurisdiction.jurisdictionId })
      .subscribe(y => {
        if (y !== null || y !== undefined) {
          this.columnName = []
          y.ColumnList.forEach(u => {
            this.columnName.push({
              id: u.id, columnTitle: u.columnName, isVisible: u.isVisible
              , isFilterable: u.isFilterable, filterCode: u.filterCode, columnField: u.columnDBName
            })
          })
          this.elements = y.Datatable!;
          this.elementsMeta = this.columnName.filter(u => u.isVisible == true)
        }
      })
  }

  public handleCellClickEvent(item: GIRGridCellEvent) {
    this.popupSpecificTitle = item.columnName + '  for ' + this.selectedJurisdiction.jurisdictionName;
    this.cellDescription = item.cellDescription;
    this.displayPopup = true
  }

  public closePopup(val: any) {
    this.displayPopup = false;
  }

  public excelEvent() {
    this.infoExchangeGrid.exportToExcel();
  }

  public pdfEvent() {
    this.infoExchangeGrid.exportToPdf();
  }

  public printEvent() {
    this.infoExchangeGrid.printGrid();
  }
}



