import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppSettings } from '../model/app-setting.model';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private appSettings: AppSettings;

  constructor(private http: HttpClient) { }

  public async loadAppSettings() {
    return this.http.get('./appSettings.json')
      .toPromise()
      .then((settings: AppSettings) => {
        this.appSettings = settings;
      });
  }

  public getAllSettings() {
    return this.appSettings;
  }

  public get(key: string) {
    return this.appSettings[key];
  }

}