
<kendo-dialog class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': dispPopUp ? 'block' :'none'}">
    <div class="card cardwidth">
      <div class="modal-header">
        <h5 class="modal-title">{{fileTitle}}</h5>
      </div>
         <div>
              <iframe [src]="fileSafeUrl" class="display-file" ></iframe>
          </div>
      <div class="modal-footer">
        <button  class="btn btn-action-primary text-nowrap me-5" (click)="openFile($event)">Open Document in New Tab</button>
        <a class="btn btn-action-primary text-nowrap me-5" [href]="fileLink" [download]="fileName">Download File</a>       
        <button type="button" class="btn btn-secondary" (click) ="closePopUp($event)" >Close</button>
      </div>
    </div>
</kendo-dialog> 

