

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': dispPopUp ? 'block' :'none'}">
  <div class="card cardwidth">
     <div class="modal-header">
     <button type="button" class="btn-close" aria-label="Close" (click)="closePopUp(true)" ></button>
    </div>
    <div class="ms-3 me-3 mt-5">
      <p>File Download is in Progress : </p>
     <kendo-progressbar [progressCssStyle]="progressStyles" [value]="loadPercentage"  [label]="progressStatus" ></kendo-progressbar>
 </div>
            
   
        <div [hidden]="true"> 
          <kendo-grid #KGrid class="kendo-grid" 
               (excelExport)="onExcelExport($event)"
               [data]="finalGridData"
               [rowHeight]="36"
               [height]="gridHeight"
               [skip]="gridState?.skip"
               [pageSize]="gridState?.take"
               scrollable="virtual"
               [navigable]="true"
               >
               <kendo-grid-column  #girGridcolumn
                   [headerStyle]="{ 'background-color':'#000' ,'color' :'#fff' }"
                   [style]="{'font-size' : '12px','text-align': 'left'   }"
                   *ngFor="let item of elementsMeta!;let indexOfelement=index;" 
                   field="{{item.columnField}}"
                   title="{{item.columnTitle}}"  
                   [width]=" columnDescriptor.isExpanded ?  (columnDescriptor.columnName  == item.columnTitle ? columnDescriptor.columnSize :  columnDescriptor.otherColumnSize) : 200 "
                   >       
                   <ng-template kendoGridCellTemplate let-dataItem>
                           <div class="cellFormat" title="{{dataItem[item.columnField]}}"
                                   [innerHTML]="dataItem[item.columnField]">
                                   <ng-container *ngIf="item.columnTitle| displayDetailsIcon">
                                    <span>Details</span></ng-container>
                           </div>
                </ng-template>
               </kendo-grid-column>
               
               <ng-template kendoGridLoadingTemplate>
                  <div class="k-loading-color"></div>
                  <div class="loading-container">
                    <kendo-loader type="converging-spinner" [themeColor]="primary" size="large"></kendo-loader>
                  </div>
                </ng-template>
      
               <kendo-grid-excel [fileName]="gridExportFileName + '.xlsx'"></kendo-grid-excel>
               <kendo-grid-pdf [autoPrint]="autoPrint" 
                               [allPages]="false"
                               paperSize="A2" 
                               [repeatHeaders]="true"
                               [fileName]="gridExportFileName + '.pdf'">
                       <kendo-grid-pdf-margin top="3cm" left="0.1cm" right="0.1cm"  bottom="3cm" >                        
                       </kendo-grid-pdf-margin>
                           <ng-template kendoGridPDFTemplate>
                               <div class="pdf-header"> {{pdfTitle}} 
                                 <div class="pdf-sub-header">{{copyRight}}</div>
                               </div>
                           </ng-template>
               </kendo-grid-pdf>           
       </kendo-grid>         
        </div> 
  </div>
</div>



 