import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { SubCategory, TrackerSections } from '../model/tracker.models';

@Injectable({
  providedIn: 'root'
})
export class TileServiceService extends BaseService {
  private readonly GetTrackerURL = this.generateAPIUrl('CRSFatca/GetTrackerSections');
  private readonly GetTrackerViewURL = this.generateAPIUrl('CRSFatca/GetTrackerTreeViewNew');
  private readonly GetFatcaURL = this.generateAPIUrl('CRSFatca/GetTrackerDataCrsFatca');
  private readonly GetCountrieFatcaURL = this.generateAPIUrl('CRSFatca/GetCountriesWithDataCrsFatca');
  private readonly GetHighLevelSummaryURL = this.generateAPIUrl('CRSFatca/GetHighLevelSummary');
  private readonly GetAEOIInformationURL = this.generateAPIUrl('CRSFatca/GetAEOIInformation');
  private readonly GetAEOIBinaryURL = this.generateAPIUrl('CRSFatca/GetAEOIFile');
  private readonly GetCrsFatcaAttachmentListURL = this.generateAPIUrl('CRSFatca/GetCRSFatcaAttachmentList');
  private readonly GetCrsFatcattachmentURL = this.generateAPIUrl('CRSFatca/GetCountriesWithDataCrsFatca');
  private readonly GetCrsFactaHistryURL = this.generateAPIUrl('CRSFatca/GetCrsFatcaHistory');
  private readonly GetEnterprisePolicyURL = this.generateAPIUrl('Dashboard/GetEnterprisePolicy');
  private readonly SaveViewCrsNewURL = this.generateAPIUrl('CRSFatca/SaveViewCrsNew');
  private readonly SaveViewFatcaNewURL = this.generateAPIUrl('CRSFatca/SaveViewFatcaNew');
  private readonly SaveEnterprisePolicyURL = this.generateAPIUrl('Dashboard/PublishEnterprisePolicy');
  private readonly GetLastupdatedDateURL = this.generateAPIUrl('Dashboard/GetConfiguration');

  constructor(private injector: Injector) {
    super(injector)
  }

  public getTrackerByTileId(payload: any): Observable<TrackerSections[]> {
    return this.http.post<TrackerSections[]>(this.GetTrackerURL, payload);
  }

  public getTrackerViewByTrackerId(payload: any): Observable<SubCategory[]> {
    return this.http.post<SubCategory[]>(this.GetTrackerViewURL, payload);
  }

  public getCountriesWithDataFatcaData(payload: any): Observable<any> {
    return this.http.post<any>(this.GetCountrieFatcaURL, payload);
  }

  public getFatcaData(payload: any): Observable<any> {
    return this.http.post<any>(this.GetFatcaURL, payload);
  }

  public getHighLevelSummary(): Observable<any> {
    return this.http.post<any>(this.GetHighLevelSummaryURL, null);
  }

  public getAEOIInformation(): Observable<any> {
    return this.http.post<any>(this.GetAEOIInformationURL, null);
  }

  public getCrsFatcaAttachmentList(payload: any): Observable<any> {
    return this.http.post<any>(this.GetCrsFatcaAttachmentListURL, payload)
  }

  public getCrsFatcaHistory(payload: any): Observable<any> {
    return this.http.post<any>(this.GetCrsFactaHistryURL, payload)
  }

  public getEnterprisePolicy(payload: any): Observable<any> {
    return this.http.post<any>(this.GetEnterprisePolicyURL, payload)
  }

  public saveViewCrsNew(payload: any): Observable<any> {
    return this.http.post<any>(this.SaveViewCrsNewURL, payload)
  }

  public saveViewFatcaNew(payload: any): Observable<any> {
    return this.http.post<any>(this.SaveViewFatcaNewURL, payload)
  }  
  
  public SaveEnterprisePolicy(payload: any): Observable<any> {
    return this.http.post<any>(this.SaveEnterprisePolicyURL, payload)
  }

  public GetLastUpdatedDate(payload: any): Observable<any> {
    return this.http.post<any>(this.GetLastupdatedDateURL, payload)
  }
}
