

export class ColumnTitle{
    columnField: string  ;
    columnTitle: string ;
}

export class GirdColumn {
    public id : number =0
    public isFilterable? : boolean = false
    public filterCode? : string | undefined
    public columnField? : string | undefined 
    public columnTitle? : string | undefined 
    public isVisible? : boolean | false
  } 
  
 export class GIRGridCellEvent {
    columnId? : number | undefined;
    columnName? : string | undefined;
    dataElement? : string | undefined;
    dataElementId? : number | undefined ;
    cellDescription? : string | undefined ;
    IDAEOIReporting? : string | undefined;
    id?: string | undefined;
    columnNameQiQDD? :  string | undefined;
    IsCountrySpecific?:  boolean | undefined;
    dataElementNameQDD? : string | undefined;
    jurisdictionName? : string | undefined;
    newsType ? : string | undefined;
    newsId ? : string | undefined;
    jurisdictionNameNews? : string | undefined
    }
  
    export class cellDataPipeResult{
        type : string | undefined
        displayValue : string | undefined
        linkString : string | undefined
      }

    export class ExpandColumnDescriptor{
       public columnName : string | undefined 
       public columnSize : number | undefined
       public otherColumnSize : number | undefined
       public isExpanded : boolean = false
    }

    export enum ExportDocumentType{
      Pdf = 1,
      Excel = 2
    }