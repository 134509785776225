import { DOCUMENT } from '@angular/common';
import { Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { AppSettings } from '../model/app-setting.model';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  private document: Document;
  private renderer: Renderer2;
  private appSettings: AppSettings;
  
  constructor(injector: Injector, private rendererFactory: RendererFactory2, private appSettingsService: AppSettingsService) {
    this.document = injector.get(DOCUMENT);
    this.renderer = rendererFactory.createRenderer(null, null);
    this.appSettings = this.appSettingsService.getAllSettings();
  }

  public init(): void {
    this.cookieConsentPopup();
  }

  public cookieConsentPopup(){
    if (!this.appSettings.cookieConsentEnable) {
      return;
    }

    const script = this.renderer.createElement('script');
    script.src = this.appSettings.cookieConsentSrc;
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', this.appSettings.cookieConsentId);
    this.renderer.appendChild(this.document.head, script);
  }
}
