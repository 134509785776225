
import { Observable, of } from 'rxjs';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NO_ATTACHMENT_AVAILABLE, NO_ENTERPRISE_POLICY } from 'src/app/core/model/constants';
import { TileCode } from 'src/app/core/model/dashboard.model';
import { GIRGridCellEvent, GirdColumn } from 'src/app/core/model/data-grid.model';
import { GIRUserRole } from 'src/app/core/model/enum.model';
import { KeyValueSelection } from 'src/app/core/model/key-value-pair.model';
import { Jurisdiction, TrackerSections } from 'src/app/core/model/tracker.models';
import { AuthService } from 'src/app/core/services/auth.service';
import { TileServiceService } from 'src/app/core/services/tile-service.service';
import { DataGridComponent } from '../data-grid/data-grid.component';

@Component({
  selector: 'app-crs-fatca-grid',
  templateUrl: './crs-fatca-grid.component.html',
  styleUrls: ['./crs-fatca-grid.component.scss']
})
export class CrsFatcaGridComponent {

  @Input() elements: Object[] = []
  @Input() elementsMeta: Object[] = []
  @Input() columnName: GirdColumn[] = []
  @Input() availableCountries: Jurisdiction[] = []
  @Input() tileService: TileServiceService
  @Input() selectedTracker: TrackerSections | undefined
  @Input() enableViewHistory: boolean = false
  @Input() heightOffset: number;
  @Input() showExcel: boolean = true

  public cellIsNonJurisdictionType: boolean = false
  public popupSpecificTitle: string | undefined
  public cellDescription: string | undefined
  public attachementList: KeyValueSelection[] | []
  public enterprisePolicy: string | undefined
  public enterprisePolicyString: string | undefined
  public viewPopup: boolean = false;
  public noattachmentAvailable: string = NO_ATTACHMENT_AVAILABLE
  public dataGridEvent: GIRGridCellEvent | undefined
  public get TileCode(): typeof TileCode { return TileCode; }
  public jurisdictionIdObs$: Observable<any>
  public dataElementIdObs$: Observable<any>
  public historyPopup: boolean | undefined
  public historyElements: Object[] = []
  public historyElementsMeta: Object[] = []
  public saveEnterprisePolicy: boolean = false
  public userRole: number | undefined;
  public eventMessage: string | undefined;
  public get GIRUserRole(): typeof GIRUserRole { return GIRUserRole; }
  @ViewChild('crsFatcaGrid') crsFatcaGrid: DataGridComponent;
  constructor(private authService: AuthService, private elem: ElementRef) { }


  ngOnInit() {
    this.userRole = Number(this.authService.getAuthUser().role)
    this.elem.nativeElement.style.setProperty('--grid-height-offset', (this.heightOffset.toString() + 'px'));
  }

  public handleCellClickEvent(item: GIRGridCellEvent) {
    this.dataGridEvent = item
    this.cellIsNonJurisdictionType = this.availableCountries.filter(x => x.jurisdictionName == item.columnName).length > 0 ? false : true;
    let country = this.availableCountries.find(x => x?.jurisdictionName.toLocaleLowerCase() == item.columnName.toLocaleLowerCase());

    this.popupSpecificTitle = item.columnName + '  for ' + item.dataElement;
    this.cellDescription = item.cellDescription;
    if (!this.cellIsNonJurisdictionType) {
      let payload = { jurisdictionId: country?.jurisdictionId, dataElementId: item?.dataElementId }
      this.tileService.getCrsFatcaAttachmentList(payload)
        .subscribe(resp => {
          this.attachementList = resp
        })
      this.tileService.getEnterprisePolicy(payload)
        .subscribe(resp => {
          this.enterprisePolicy = resp;
          if (this.enterprisePolicy.length == 0)
            this.enterprisePolicy = NO_ENTERPRISE_POLICY;
        })
    }
    this.viewPopup = true
  }

  public closePopUp(val: any) {
    this.cellIsNonJurisdictionType = undefined;
    this.popupSpecificTitle = undefined
    this.cellDescription = undefined
    this.attachementList = undefined
    this.enterprisePolicy = undefined
    this.viewPopup = false
  }

  public showHistoryEventM() {
    let item: GIRGridCellEvent = this.dataGridEvent
    if (item != undefined) {
      this.popupSpecificTitle = item.columnName + '  for ' + item.dataElement;
      let country = this.availableCountries.find(x => x.jurisdictionName.toLocaleLowerCase() == item.columnName.toLocaleLowerCase());
      let payload = {
        trackerId: this.selectedTracker?.id,
        jurisdictionId: country.jurisdictionId,
        dataElementId: item.dataElementId
      }
      this.historyElementsMeta = [];
      this.historyElementsMeta.push({
        id: 1, columnField: 'publishedOn', columnTitle: 'PublishedOn',
        isVisible: true, isFilterable: true, filterCode: 'MSF'
      })
      this.historyElementsMeta.push({
        id: 2, columnField: 'value', columnTitle: 'Content',
        isVisible: true, isFilterable: true, filterCode: 'NF'
      })
      this.tileService.getCrsFatcaHistory(payload)
        .subscribe(
          resp => {
            this.historyElements = resp;
            this.historyPopup = true;
          }
        )
    }
  }

  public publishEnterPrisePolicy() {
    this.saveEnterprisePolicy = true;
    let country = this.availableCountries.find(x => x?.jurisdictionName.toLocaleLowerCase() == this.dataGridEvent.columnName.toLocaleLowerCase());
    this.jurisdictionIdObs$ = of(country?.jurisdictionId ?? 0)
    this.dataElementIdObs$ = of(this.dataGridEvent?.dataElementId ?? 0)
  }

  public closePopup(val: any) {
    this.saveEnterprisePolicy = false;
  }

  public closeHistroyPopup(val: any) {
    this.historyElements = [];
    this.historyElementsMeta = [];
    this.historyPopup = false
  }

  public excelEvent() {
    this.crsFatcaGrid.exportToExcel();
  }
}
