import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonDataService } from 'src/app/core/services/common-data.service';

@Component({
  selector: 'app-datagrid-buttons',
  templateUrl: './datagrid-buttons.component.html',
  styleUrls: ['./datagrid-buttons.component.scss']
})
export class DatagridButtonsComponent {

  @Input() Excel: boolean = false
  @Input() Print: boolean = false
  @Input() Pdf: boolean = false
  @Input() ClearFiler: boolean = false
  @Input() Topics: boolean = false
  @Input() Delete: boolean = false

  @Output() excelEvent = new EventEmitter();
  @Output() pdfEvent = new EventEmitter();
  @Output() printEvent = new EventEmitter();
  @Output() topicClickEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();

  constructor(private commonDataService: CommonDataService) {

  }

  public topicClickEventMethod() {
    this.topicClickEvent.emit('true');
  }

  public clearAllFilterEventMethod() {
    this.commonDataService.clearAllFilter$.next('true');
  }

  public excelEventMethod() {
    this.excelEvent.emit();
  }

  public pdfEventMethod() {
    this.pdfEvent.emit()
  }

  public printEventMethod() {
    this.printEvent.emit()
  }

  public deleteEventMethod() {
    this.deleteEvent.emit('true')
  }
}
