<app-inner-section-layout>
  <app-title-bar [title]="title" [showLastupdated]="showLastupdated" [faicon]="faicon"></app-title-bar>

  <div class="p-4 form-group d-flex form-line">
    <label class="col-sm-2 col-form-label message-heading" type="text">Date Issued From</label>
    <div class="col-sm-2">
      <input type="date" [(ngModel)]="fromDate">
    </div>
    <label class="col-sm-2 col-form-label message-heading" type="text">Date Issued To</label>
    <div class="col-sm-2">
      <input type="date" [(ngModel)]="toDate">
    </div>
    <div class="col-sm-4 end">
      <button class="btn btn-action-primary text-nowrap me-3" (click)="getNewsAndAlertDetailByDate()">
        <em class="fas fa-filter me-2" aria-hidden="true"></em>Filter
      </button>
    </div>
  </div>

  <app-datagrid-buttons [Excel]="true" [Pdf]="true"  [ClearFiler]="true" (excelEvent)="excelEvent()"
    (pdfEvent)="pdfEvent()" (printEvent)="printEvent()" >
  </app-datagrid-buttons>

  <app-data-grid #newsAlertGrid [elementsMeta]="elementsMeta" [elements]="elements" [heightOffset]="285" [columnDescriptor]="columnDescriptor" 
    (handleCellClickEvent)="handleCellClick($event)" gridExportFileName="News Alert" [isPdfEnabled]="true" [pdfTitle]="docTitle"></app-data-grid>

  <app-pop-up [dispPopUp]="displayStyle" [displayHeaderSection]='false' (closedPopUpEvent)="closePopup($event)">
    <h4 class="mb-4 popupSepcificTitle">{{popupSpecificTitle}}</h4>
    <div class="mb-4" [innerHTML]="cellDescription | toInnerHtmlCompatible"></div>
    <ng-container *ngIf="displayAttachments == true">
      <app-file-link-display [docList]="newsAndAlertAttachmentList"
        [tileType]="TileCode.NewsAndAlerts"></app-file-link-display>
    </ng-container>
  </app-pop-up>

</app-inner-section-layout>