import { Pipe, PipeTransform } from '@angular/core';
import { WEB_LINK_01, WEB_LINK_02 } from 'src/app/core/model/constants';

@Pipe({
  name: 'displayLink'
})
export class DisplayLinkPipe implements PipeTransform {
  transform(val: string): unknown {
    let isLink = false;
    if (val?.length > 0) {
      isLink = val.toLocaleLowerCase().includes(WEB_LINK_01)
      if (!isLink)
        isLink = val.toLocaleLowerCase().includes(WEB_LINK_02)
    }
    return isLink
  }

}
