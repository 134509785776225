import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-user',
  templateUrl: './invalid-user.component.html',
  styleUrls: ['./invalid-user.component.scss']
})

export class InvalidUserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
