import { Injectable, Injector } from '@angular/core';
import { UserManager, WebStorageStateStore, UserManagerSettings, User as OidcUser } from 'oidc-client';
import { Observable, BehaviorSubject, from, EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { BaseService } from './base.service';
import { User } from '../model/user.model';
import { NavigationService } from './navigation.service';
import { INVALID_USER_PAGE } from '../model/constants';
import { AppSettingsService } from './app-settings.service';
import { Router } from '@angular/router';

const TOKEN_KEY = 'access_token';
const USER_PROFILE_KEY = 'authUser';


@Injectable({
  providedIn: 'root'
})

export class AuthService extends BaseService {
  private readonly GET_USER_GUID = this.generateAPIUrl('User/Login');
  private readonly Form_Login= this.generateAPIUrl('User/FormLogin');
  private readonly RENEW_ACCESS_TOKEN = this.generateAPIUrl('Token/RenewAccessToken');
  private readonly USER_LOGOUT = this.generateAPIUrl('User/Logout');
  public authUserSubject: BehaviorSubject<User>;
  public authUser$: Observable<User>;
  private mgr: UserManager;

  private AppSettingsService : AppSettingsService
  private formLoginSites : string [] =[]

  constructor(injector: Injector, private navigationService: NavigationService
      ,private router : Router)
   {
    super(injector);
    this.authUserSubject = new BehaviorSubject<User>(this.getAuthUser());
    this.authUser$ = this.authUserSubject.asObservable();
    this.mgr = new UserManager(this.azureADSettings());

    this.appSettingsService = injector.get(AppSettingsService);
    this.appSettings = this.appSettingsService.getAllSettings();
    
    this.appSettings.formLoginURLs?.split(' ')
        .forEach(x=> this.formLoginSites.push(x.toLocaleLowerCase().replace('https://','').replace('http://','')));
    
  }

  public get authUser(): User {
    return this.authUserSubject.value;
  }

  public azureADSettings(): UserManagerSettings {
    return {
      authority: this.appSettings.azureADAuthority,
      client_id: this.appSettings.azureADClientId,
      redirect_uri: this.appSettings.azureADRedirectUrl,
      post_logout_redirect_uri: this.appSettings.azureADPostLogoutRedirectUri,
      response_type: 'code',
      scope: 'openid email profile',
      accessTokenExpiringNotificationTime: 120,
      filterProtocolClaims: true,
      loadUserInfo: false,
      extraQueryParams: {
        resource: this.appSettings.azureADClientId
      }
    };
  }

  public isFormLoginEnabled(siteURL : string) : boolean{
    let siteDomainName = siteURL.split('/')[2];
    return this.formLoginSites.find(x=> x == siteDomainName) != undefined;
  }
  
  public isAuthenticated(): boolean {
    return (this.isValidToken() && this.isValidUserSession());
  }

  private isValidUserSession(): boolean {
    if (this.authUser != null && this.authUser.tokenExpiration) {
      const now = new Date();
      const expirationDate = new Date(this.authUser.tokenExpiration);
      return now.getUTCDate() < expirationDate.getTime();
    }
    return false;
  }

  private isValidToken(): boolean {
    if (this.authUser != null && this.authUser.tokenExpiration) {
      const now = new Date();
      const expirationDate = new Date(this.authUser.tokenExpiration);
      return now.getUTCDate() < expirationDate.getTime();
    }
    return false;
  }

  private setToken(token: string) {
    sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  private setAuthUser(user: User): void {
    this.authUserSubject.next(user);
    sessionStorage.setItem(USER_PROFILE_KEY, btoa(JSON.stringify(user)));
  }

  public getAuthUser(): User {
    const userStr = sessionStorage.getItem(USER_PROFILE_KEY);
    return userStr ? JSON.parse(atob(userStr)) : null;
  }

  private mapUserProfile(profile: any) {
    const user = new User();
    user.emailId = profile.email;
    user.firstName = profile.given_name;
    user.lastName = profile.family_name;
    return user;
  }

  public logout() {
  this.http.post<any>(this.USER_LOGOUT, null).subscribe(() => {
        const user = this.getAuthUser();
        const now = new Date();
        user.tokenExpiration = now;
        this.setAuthUser(user);
        sessionStorage.clear();
        
       if(!this.isFormLoginEnabled(window.location.href)) 
        this.mgr.signoutRedirect();
       else 
         this.router.navigateByUrl('/logout')

        this.mgr = null;
      }
    );
  }

  public redirectToAzureAD() {
    this.mgr.clearStaleState();
    this.mgr.signinRedirect();
  }

  public login(callbackUrl: string): Observable<any> {
      return from(this.mgr.signinRedirectCallback(callbackUrl)).pipe(
        tap((oidcUser: OidcUser) => {
          const user = this.mapUserProfile(oidcUser.profile);
          user.idToken = oidcUser.id_token;
          this.setAuthUser(user);
        }),
        switchMap((oidcUser: OidcUser) => this.getUserGuid(oidcUser))
      );
  }

  private getUserGuid(oidcUser: OidcUser): Observable<any> {
    const user = this.getAuthUser();
    const idToken = user.idToken;
    return this.http.post(this.GET_USER_GUID, { idToken }).pipe(
      catchError((error) => {
        return this.notify(error);
      }),
      tap((resp) => {
        user.userId = resp.guid;
        user.companyId = resp.companyId;
        user.role = resp.userRole;
        user.idToken = oidcUser.id_token;
        this.setAuthUser(user);
        this.setAccessToken(resp.token)
      })
    );
  }

  public formLogin(payload : any): Observable<any>{
    const user = new User();
    user.emailId = '';
    return this.http.post(this.Form_Login,payload).pipe(
      catchError((error) => {
        return this.notify(error);
      }),
      tap((resp : any) => {
          if(resp ==  null || resp == undefined)
            throw new Error("Not Autherized")
    
          user.userId = resp.guid;
          user.companyId = resp.companyId;
          user.role = resp.userRole;
          user.idToken = '';
          user.firstName = resp.firstname;
          user.lastName =  resp.lastName;
          this.setAuthUser(user);
          this.setAccessToken(resp.token)
        
      }));
  }

  private notify(error): any {
    if (error.status === 422) {
      this.navigationService.navigateToUrl(INVALID_USER_PAGE);
    }
    return EMPTY;
  }
  public renewAccessToken() {
    this.http.post<any>(this.RENEW_ACCESS_TOKEN, null).subscribe((token) => {
      this.setAccessToken(token);
    });
  }

  private setAccessToken(token): void {
    const user = this.getAuthUser();
    this.setToken(token.accessToken);
    user.tokenExpiration = token.tokenExpirationDateTime;
    this.setAuthUser(user);
  }

  public UpdateTokenExpiration() {
    if (this.authUser !== null) {
      const now = new Date();
      const currentDate = new Date(now.setMinutes(now.getMinutes() + 2));
      const expirationDate = new Date(this.authUser.tokenExpiration);
      if(expirationDate.getTime() < currentDate.getTime())
      {
        this.renewAccessToken();
      }
    }
  }
}
